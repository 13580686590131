import React, { useState } from "react";
import { Table, Transfer } from "antd";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const TableTransferComponent = (props) => {
  const {
    dataSource: transferDataSource,
    leftColumns,
    rightColumns,
    setCategories,
    filterOption,
    targetKeys,
    onChange,
    handleSearch,
  } = props;

  const [dataSource, setDataSource] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setCategories((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);

        if (prev[activeIndex].key && prev[overIndex]?.key) {
          // swapping index
          const tempKey = prev[activeIndex]?.key;

          prev[activeIndex].key = prev[overIndex]?.key;

          prev[overIndex].key = tempKey;

          const previousFinal = prev.map((elm, i) => {
            return {
              ...elm,
              sequence: i + 1,
            };
          });

          const arrayMoveValue = arrayMove(
            previousFinal,
            activeIndex,
            overIndex
          );

          return arrayMoveValue;
        }
        return prev;
      });
    }
  };

  const onInvertAllDataHandler = (
    changeableRowKeys,
    onItemSelectAll,
    direction
  ) => {
    let keyArr = [];

    if (changeableRowKeys.length <= 0) {
      const leftKeyArray = transferDataSource
        .map((_, i) => i.toString())
        .filter((index) => !targetKeys.includes(index));

      const rightKeyArray = targetKeys.map((index) => index.toString());
      const finalArray = direction === "right" ? rightKeyArray : leftKeyArray;

      return onItemSelectAll(finalArray, "replace");
    }
    if (direction === "left") {
      transferDataSource.forEach((_, index) => {
        if (!changeableRowKeys.includes(index.toString())) {
          keyArr.push(index.toString());
        }
      });

      keyArr = keyArr.filter((index) => !targetKeys.includes(index));
    } else {
      targetKeys.forEach((e) => {
        if (!changeableRowKeys.includes(e.toString())) {
          keyArr.push(e.toString());
        }
      });
    }

    onItemSelectAll(keyArr, "replace");
  };

  return (
    <Transfer
      style={{
        width: "100%",
      }}
      onSearch={handleSearch}
      dataSource={transferDataSource}
      targetKeys={targetKeys}
      showSearch
      showSelectAll={false}
      onChange={onChange}
      filterOption={filterOption}
      leftColumns={leftColumns}
      rightColumns={rightColumns}
    >
      {({
        direction,
        filteredItems,
        onItemSelect,
        onItemSelectAll,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        if (direction === "right") {
          setDataSource(filteredItems);
        }
        const rowSelection = {
          selectedRowKeys: listSelectedKeys,
          onChange: (_selectedRowKeys) => {
            onItemSelectAll(_selectedRowKeys, "replace");
          },
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
              text: "Invert all data",
              onSelect: () =>
                onInvertAllDataHandler(
                  listSelectedKeys,
                  onItemSelectAll,
                  direction
                ),
            },
          ],
        };
        return direction === "left" ? (
          <Table
            rowSelection={rowSelection}
            columns={leftColumns}
            dataSource={filteredItems}
            rowClassName="editable-row"
            size="small"
            style={{
              pointerEvents: listDisabled ? "none" : undefined,
            }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick:
                direction === "left"
                  ? () => {
                      if (itemDisabled || listDisabled) {
                        return;
                      }
                      onItemSelect(key, !listSelectedKeys.includes(key));
                    }
                  : () => {},
            })}
          />
        ) : (
          <>
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={(a) => onDragEnd(a, "right")}
            >
              <SortableContext
                // rowKey array
                items={dataSource?.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  pagination={false}
                  rowSelection={rowSelection}
                  components={{
                    body: {
                      row: Row,
                    },
                  }}
                  columns={rightColumns}
                  dataSource={dataSource}
                  rowClassName="editable-row"
                  size="small"
                  style={{
                    pointerEvents: listDisabled ? "none" : undefined,
                  }}
                  onRow={({ key, disabled: itemDisabled }) => ({
                    onClick:
                      direction === "left"
                        ? () => {
                            if (itemDisabled || listDisabled) {
                              return;
                            }
                            onItemSelect(key, !listSelectedKeys.includes(key));
                          }
                        : () => {},
                  })}
                />
              </SortableContext>
            </DndContext>
          </>
        );
      }}
    </Transfer>
  );
};

export default TableTransferComponent;
