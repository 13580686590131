export const Role = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  USER: "User",
};

export const CATEGORY_TYPES_ENUM = {
  template: "template",
  sticker: "sticker",
};

export const showProductItemInNav = ["/product", "/product/details"];

export const DefaultPagingValue = {
  PAGE_SIZE: 10,
  PAGE_INDEX: 1,
  TOTAL_PAGES: 1,
};

export const MASKED_BUILD_VERSION = "0[0][0].0[0][0].0[0][0].0[0][0][0]";
export const DOB_FORMAT = "yyyy-MM-DD";
export const BUILDDATE_FORMAT = "yyyy-MM-DD";
export const DEFAULT_SEQUENCE_NAME = "DEFAULT_CAMPAIGN";
