const TotalReocordsInListingComponent = (props) => {
  const { totalRecords } = props;
  return (
    <div style={{ display: "flex ", justifyContent: "end" }}>
      <p>Total Records : </p>{" "}
      <div
        style={{
          color: "#1677ff",
          width: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {totalRecords}
      </div>
    </div>
  );
};
export default TotalReocordsInListingComponent;
