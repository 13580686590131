import { message } from "antd";
import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import DashboardListing from "../../Components/Dashboard/DashboardListing";

const DashboardContainer = () => {
  const [showLoading, SetShowLoading] = useState(false);
  const [dashboardData, SetDashboardData] = useState();
  const [isDataLoaded, SetIsDataLoaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!dashboardData) {
      fetchAllDashboardData();
    }
  }, []);

  const fetchAllDashboardData = async () => {
    SetShowLoading(true);

    const response = await httpClient.get(
      `${Urls.DASHBOARD_GET_DASHBOARD_DATA}`
    );
    if (isSuccessStatus(response?.status)) {
      SetDashboardData(response?.data.data);
      if (response?.data.data) {
        console.log(response?.data.data);

        SetIsDataLoaded(true);
        // messageApi.open({
        //   type: "success",
        //   content: response.data.message,
        //   duration: 2,
        // });
      }
    } else {
    }
    SetShowLoading(false);
  };

  return (
    <>
      {contextHolder}
      {isDataLoaded && <DashboardListing dataSource={dashboardData} />}
    </>
  );
};

export default DashboardContainer;
