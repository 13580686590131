import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddCategory from "../../Components/Category/addCategory";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { isNullOrEmpty, objectToQueryString } from "../../Util/commonUtility";
import { DefaultPagingValue, Role } from "../../Constant/Common";
import CategoryListing from "../../Components/Category/categoryListing";
import MessageNotification from "../../Components/Common/messageNotification";

const CategoryContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoading, SetShowLoading] = useState(false);
  const [isAddEditCategory, SetIsAddEditCategory] = useState(false);
  const authState = useSelector((state) => state.auth);
  const [categories, SetCategories] = useState([]);
  const [categoriesType, SetCategoriesType] = useState([]);
  const [editCategoryData, SetEditCategoryData] = useState();
  const [filterData, setFilterData] = useState(undefined);
  const [categoriesTypeOptions, SetCategoriesTypeOptions] = useState([]);
  const [totalRecords, SetTotalRecords] = useState("");

  const [searchData, setSearchData] = useState();
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortTitle, setSortTitle] = useState("Click to Sort Descending");
  const searchInput = useRef(null);

  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  useEffect(() => {
    fetchAllCategories();
    fetchAllCategoriesTypes();
  }, [paging.pageIndex, paging.pageSize, filterData]);

  const submitAddCategoryForm = async (data) => {
    SetShowLoading(true);

    const isAllLocationsSelected = data.location.includes("ALL");
    const currentLocations = isAllLocationsSelected ? ["ALL"] : data.location;

    if (data._id) {
      const response = await httpClient.put(
        Urls.CATEGORY_UPDATE_CATEGORY_BY_ID.replace("#{categoryId}", data._id),
        { ...data, location: currentLocations }
      );
      if (isSuccessStatus(response?.status)) {
        MessageNotification("success", response.data.message);
        resetEditFormState();
        fetchAllCategories();
      } else {
      }
    } else {
      let newData = data;
      if (newData.sequence === undefined) {
        newData.sequence = 1;
      } else if (newData.sequence === null) {
        newData.sequence = newData.sequence;
      }
      const response = await httpClient.post(Urls.CATEGORY_ADD_CATEGORY, {
        ...newData,
        location: currentLocations,
      });
      if (isSuccessStatus(response?.status)) {
        MessageNotification("success", response.data.message);
        resetEditFormState();
        fetchAllCategories();
      } else {
      }
    }

    SetIsAddEditCategory(false);
    SetShowLoading(false);
  };

  const fetchAllCategories = async () => {
    SetShowLoading(true);
    let filterDatas = "";
    if (filterData) {
      filterDatas = objectToQueryString(filterData);
    }

    const response = await httpClient.get(`${
      Urls.CATEGORY_GET_ALL_CATEGORIES
    }?page=${paging.pageIndex}
  &limit=${paging.pageSize}&${filterDatas && filterDatas}`);
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetCategories(response?.data.data.categories);
      SetTotalRecords(response?.data.data.totalCount);
      if (response?.data.data) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_SIZE,
        });
      }
    } else {
    }
    SetShowLoading(false);
  };

  const deleteCategory = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.CATEGORY_DELETE_CATEGORY_BY_ID.replace(
        "#{categoryId}",
        data._id
      )}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification("success", response.data.message);
      fetchAllCategories();
    } else {
    }
    SetShowLoading(false);
  };

  const fetchAllCategoriesTypes = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.CATEGORY_GET_ALL_CATEGORIES_TYPES}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetCategoriesType(response?.data.data);
      let options = [];
      response?.data.data.findCategoryTypes.forEach((element) => {
        let option = {
          label: element.name,
          value: element._id,
        };
        options.push(option);
      });

      SetCategoriesTypeOptions(options);
    } else {
    }
    SetShowLoading(false);
  };

  const editCategory = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.CATEGORY_GET_CATEGORY_BY_ID.replace("#{categoryId}", data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification("success", response.data.message);
      SetIsAddEditCategory(true);
      SetEditCategoryData(response?.data?.data);
    } else {
    }
    SetShowLoading(false);
  };

  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };
  const resetEditFormState = () => {
    SetIsAddEditCategory(false);
    SetEditCategoryData(null);
  };
  const onFilterFinish = (data) => {
    setFilterData(data);
  };

  const toggleSortOrder = (order) => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const handleFilter = (value, record) => {
    setSortField(value);
    let data = {
      searchText: "",
      sortField: sortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };
  const handleSort = (column, sortOrderNew) => {
    if (sortOrderNew === "desc") {
      setSortTitle("Click to Sort Ascending");
    } else {
      setSortTitle("Click to Sort Descending");
    }
    toggleSortOrder(sortOrder);
    setSortField(column);

    let data = {
      searchText: searchData,
      sortField: column,
      order: sortOrder,
    };
    onFilterFinish(data);
  };

  const handleSearch = (searchText) => {
    setSearchData(searchText);

    let data = {
      searchText: searchText,
      sortField: sortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };

  const handleReset = () => {
    setFilterData(undefined);
    setSearchData(undefined);
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : isAddEditCategory ? (
        <AddCategory
          onFinish={(data) => {
            submitAddCategoryForm(data);
          }}
          editCategoryData={editCategoryData}
          categoriesTypeOptions={categoriesTypeOptions}
          backToListPage={() => resetEditFormState()}
        />
      ) : (
        <CategoryListing
          filterData={filterData}
          sortOrder={sortOrder}
          sortField={sortField}
          dataSource={categories}
          onFilterFinish={(data) => onFilterFinish(data)}
          onFilterClear={() => {
            setFilterData(undefined);
          }}
          sortTitle={sortTitle}
          handleSort={(column, sortOrderNew) =>
            handleSort(column, sortOrderNew)
          }
          handleFilter={(value, record) => handleFilter(value, record)}
          addCategory={() => SetIsAddEditCategory(true)}
          editCategory={(data) => editCategory(data)}
          deleteCategory={(data) => deleteCategory(data)}
          categoriesTypeOptions={categoriesTypeOptions}
          paging={paging}
          totalRecords={totalRecords}
          handlePageChange={(page, pageSize) => {
            handlePageChange(page, pageSize);
          }}
          searchedText={searchData}
          handleSearch={(searchText) => handleSearch(searchText)}
          handleReset={(clearFilters) => handleReset(clearFilters)}
          searchInput={searchInput}
        />
      )}
    </>
  );
};

export default CategoryContainer;
