import React, {
  useEffect,
  useState,
  useMemo,
  RadiusUpleftOutlined,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { DefaultPagingValue } from "../../Constant/Common";
import { message } from "antd";
import AddUserAdmin from "../../Components/User/addUser";
import UserAdminListing from "../../Components/User/userAdminListing";
import MessageNotification from "../../Components/Common/messageNotification";

const UserContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoading, SetShowLoading] = useState(false);
  const [isAddEditUser, SetIsAddEditUser] = useState(false);
  const [isResetPassword, SetIsResetPassword] = useState(false);
  const [isAddUser, SetIsAddUser] = useState(false);
  const authState = useSelector((state) => state.auth);
  const [userAdmines, SetUserAdmines] = useState([]);
  const [editUserData, SetEditUserData] = useState();
  const [resetUserPassword, SetResetUserPassword] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [totalRecords, SetTotalRecords] = useState("");

  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  useEffect(() => {
    fetchAllUserAdmines();
  }, [paging.pageSize, paging.pageIndex]);

  const submitAddUserForm = async (data) => {
    SetShowLoading(true);
    if (isAddEditUser) {
      const response = await httpClient.put(
        Urls.USER_EDIT_ADMIN_BY_ID.replace("#{adminId}", data._id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        MessageNotification("success", response.data.message);

        resetEditFormState();
        fetchAllUserAdmines();
        // open notification
      } else {
      }
    } else if (isAddUser) {
      const response = await httpClient.post(Urls.USER_ADD_ADMINS, data);
      if (isSuccessStatus(response?.status)) {
        MessageNotification("success", response.data.message);

        resetEditFormState();
        fetchAllUserAdmines();
      } else {
      }
    } else {
      SetShowLoading(true);
      if (isResetPassword) {
        const response = await httpClient.put(
          Urls.USER_EDIT_PASSWORD_BY_ID.replace("#{adminId}", data._id),
          data
        );
        if (isSuccessStatus(response?.status)) {
          MessageNotification("success", response.data.message);

          resetEditFormState();
          fetchAllUserAdmines();
        } else {
        }
      }
    }

    SetIsAddEditUser(false);
    SetShowLoading(false);
  };

  const fetchAllUserAdmines = async () => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.USER_GET_ALL_ADMINS}?page=${paging.pageIndex}&limit=${paging.pageSize}`
    );

    //(response?.data?.data);
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetUserAdmines(response?.data.data.result);
      SetTotalRecords(response?.data.data.totalCount);
      if (response?.data.data.result) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
    }

    SetShowLoading(false);
  };

  const deleteUserAdmin = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.USER_DELETE_ADMIN_BY_ID.replace("#{adminId}", data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification("success", response.data.message);

      fetchAllUserAdmines();
    } else {
    }
    SetShowLoading(false);
  };

  // const editUserAdmin = async (data) => {};

  const editUserAdmin = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.USER_EDIT_ADMIN_BY_USER__ID.replace("#{adminId}", data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      SetIsAddEditUser(true);
      SetEditUserData(response?.data?.data);
    } else {
    }
    SetShowLoading(false);
  };

  const editUserPassword = async (data) => {
    SetIsResetPassword(true);
    SetResetUserPassword(data);
  };

  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };
  const resetEditFormState = () => {
    SetIsAddEditUser(false);
    SetIsAddUser(false);
    SetIsResetPassword(false);
    SetEditUserData(true);
  };

  return (
    <>
      {contextHolder}
      {showLoading ? (
        <Loader />
      ) : isAddEditUser ? (
        <AddUserAdmin
          onFinish={(data) => {
            submitAddUserForm(data);
          }}
          editUserData={editUserData}
          isResetPassword={false}
          isAddUser={false}
          isEditUser={true}
          backToListPage={() => resetEditFormState()}
        />
      ) : isResetPassword ? (
        <AddUserAdmin
          onFinish={(data) => {
            submitAddUserForm(data);
          }}
          resetUserPassword={resetUserPassword}
          isResetPassword={true}
          isAddUser={false}
          isEditUser={false}
          backToListPage={() => resetEditFormState()}
        />
      ) : isAddUser ? (
        <AddUserAdmin
          onFinish={(data) => {
            submitAddUserForm(data);
          }}
          isResetPassword={false}
          isAddUser={true}
          isEditUser={false}
          backToListPage={() => resetEditFormState()}
        />
      ) : (
        <UserAdminListing
          dataSource={userAdmines}
          onFinish={(data) => {
            submitAddUserForm(data);
          }}
          addUserAdmin={() => SetIsAddUser(true)}
          editUserAdmin={(data) => editUserAdmin(data)}
          editUserPassword={(data) => editUserPassword(data)}
          deleteUserAdmin={(data) => deleteUserAdmin(data)}
          paging={paging}
          totalRecords={totalRecords}
          handlePageChange={(page, pageSize) => {
            handlePageChange(page, pageSize);
          }}
        />
      )}
    </>
  );
};

export default UserContainer;
