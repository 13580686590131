import React from "react";
import CategoryContainer from "../../Containers/Category";
const Category = (props) => {
  return (
    <>
      <CategoryContainer />
    </>
  );     
};
export default Category;
