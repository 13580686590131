import React from "react";
import { Form, Divider, Select, Input, Switch } from "antd";

function DynamicField(props) {
  const { formData, dataObject } = props;
  const Fields = () => {
    const createField = (field) => {
      if (field.htmlType === "text") {
        return (
          <Input
            placeholder={"Please select " + field.name}
            className="add_form"
            defaultValue={dataObject ? dataObject[field.name] : ""}
          />
        );
      }
      if (field.htmlType === "boolean") {
        return (
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={dataObject ? dataObject[field.name] : false}
          />
        );
      }
      return <></>;
    };

    const fields = formData.fields.map((field, index) => (
      <Form.Item
        key={index}
        name={field.name}
        label={field.label}
        rules={[
          {
            required: field.isRequired,
            message: "Please enter " + field.label,
          },
        ]}
        initialValue={dataObject ? dataObject[field.name] : ""}
      >
        {createField(field)}
      </Form.Item>
    ));

    return <div>{fields}</div>;
  };

  return (
    <Form.List name="fields">
      {(fields, { add, remove }) => {
        return <div>{Fields()}</div>;
      }}
    </Form.List>
  );
}

export default DynamicField;
