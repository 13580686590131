const ShowLayeredImages = (props) => {
  const { images } = props;
  return (
    <div className="layers">
      {images?.map((i, index) => (
        <>
          <div className="layer_img" key={index}>
            <img src={i.imageUrl} />
          </div>
        </>
      ))}
    </div>
  );
};
export default ShowLayeredImages;
