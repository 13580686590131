import "./Scss/application.scss";
import AppRoute from "./Routes/AppRoute";

function App() {
  return (
    <>
      {" "}
      <AppRoute />
      <contextHolder />
    </>
  );
}

export default App;
