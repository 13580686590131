import { createSlice } from "@reduxjs/toolkit";
import { accountLogin } from "./authAction";
import {
  isJson,
  removeLocalStorageAccountInfo,
  setLocalStorageAccountInfo,
} from "../../../../Util/commonUtility";
const accountData = localStorage.getItem("account");
const accessToken = localStorage.getItem("token");

let initialState = {
  accountDataStatus: "idle",
  accountData: isJson(accountData) ? JSON.parse(accountData) : undefined,
  tokenData: accessToken,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    resetUser(state, action) {
      state.accountDataStatus = "idle";
      state.accountData = {};
    },
    accountLogout(state, action) {
      removeLocalStorageAccountInfo();
      state.accountData = {};
      state.tokenData = undefined;
    },
    accountLoginRequest(state, action) {
      state.accountData = action?.payload?.data;
      state.tokenData = action?.payload?.data?.token;
      setLocalStorageAccountInfo(action?.payload?.data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(accountLogin.pending, (state, { payload }) => {
      state.accountDataStatus = "loading";
    });
    builder.addCase(accountLogin.fulfilled, (state, { payload }) => {
      state.accountDataStatus = "succeeded";
    });
    builder.addCase(accountLogin.rejected, (state, { payload }) => {
      state.accountDataStatus = "failed";
    });
  },
});

export const { resetUser, accountLogout, accountLoginRequest } =
  authSlice.actions;

export default authSlice.reducer;
