import React from "react";
import UserContainer from "../../Containers/User";

const UserAdmin = (props) => {
  return (
    <>
      <UserContainer />
    </>
  ); 
};

export default UserAdmin;
