import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Common/breadCrumb";
import { breadCrumbsPathForPages } from "../../../Util/commonUtility";
import { httpClient } from "../../../Api/client";
import Urls from "../../../Constant/Urls";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Table, Tag, Typography } from "antd";
import "./viewDetailsComponent.scss";
import NotificationService from "../../../Services/notification";
import Loader from "../../Common/Loader";
import { HttpStatusCode } from "axios";
import ISO3Array from "../../../Constant/ISO3.json";

const ViewCategorySequenceDetails = () => {
  const [categorySequenceDetails, setCategoriesSequenceDetails] = useState({});
  const [categorySequences, setCategorySequences] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const { id: categorySequenceId } = useParams();
  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.CATEGORY_GET_ALL_CATEGORIES}?limit=1000`
      );
      if (response.status === 200) {
        const mockData = response?.data?.data?.categories.map((cate, i) => ({
          ...cate,
          key: i.toString(),
        }));
        if (categorySequenceId) {
          await getCategorySequenceFromId(mockData);
          return;
        }
        setCategorySequences(mockData);
      }
    } catch (error) {
      NotificationService.error(
        "Something went Wrong while getting category sequence details"
      );
    } finally {
      setShowLoading(false);
    }
  };

  const getCategorySequenceFromId = async (categoriesRes) => {
    const response = await httpClient.get(
      Urls.GET_CATEGORY_SEQUENCE.replace("#{sequenceId}", categorySequenceId)
    );

    if (response.status === HttpStatusCode.Ok) {
      setCategoriesSequenceDetails(response.data.data);

      let indexArray = [];

      const finalCategories = categoriesRes
        .map((category, index) => {
          const isExist =
            response?.data?.data?.categorySequences.find(
              (seq) => seq.category === category._id
            ) || null;
          if (isExist) {
            indexArray.push(index.toString());
            return {
              ...category,
              sequence: isExist.sequence,
            };
          }
        })
        .filter((category) => category);

      const sortedCategoriesInAscend = finalCategories.sort(function (a, b) {
        return a.sequence - b.sequence;
      });

      setCategorySequences(sortedCategoriesInAscend);
    }
  };

  useEffect(() => {
    if (categorySequenceId) {
      getCategories();
    }
  }, [categorySequenceId]);

  const { Text } = Typography;

  const columns = [
    {
      title: "Sequence",
      dataIndex: "sequence",
      key: "sequence",
      width: "30%",
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Name",
      width: "70%",
      className: "text-start",
    },
  ];

  const routeToEditHandler = () => {
    navigate(`/categorySequenceForm/Edit/${categorySequenceId}`, {
      state: { mode: "Edit" },
    });
  };

  const renderLocations = () => {
    if (categorySequenceDetails?.location?.includes("ALL")) {
      return <Text code>All locations are selected</Text>;
    }
    return categorySequenceDetails?.location?.map((locationString) => {
      const currentLocation = ISO3Array.find(
        (location) => location.code === locationString
      );
      return (
        <Text code>{`${currentLocation.code} - ${currentLocation.name}`}</Text>
      );
    });
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <>
          <div className="bread-Crumb-Container">
            <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.Details} />
            <Button type="primary" onClick={routeToEditHandler}>
              Edit
            </Button>
          </div>
          <div className="view-category-sequence-details-container">
            <div className="text-details-wrapper">
              <b className="campaign-code-container">
                {" "}
                Campaign Code :{" "}
                <span className="text-value">
                  {categorySequenceDetails.campaignCode || "N/A"}
                </span>
              </b>
              <b>
                Campaign :{" "}
                <Tag
                  className="common-tag-color"
                  color={
                    categorySequenceDetails.isEnabled === true ? "green" : "red"
                  }
                >
                  {String(
                    categorySequenceDetails.isEnabled ? "Enabled" : "Disabled"
                  )}
                </Tag>
              </b>
              <div className="locations-wrapper">
                <b>Locations </b> :&nbsp;
                <div className="locations">{renderLocations()}</div>
              </div>
            </div>
            <div className="details-table-wrapper">
              <Table
                className="listCategory view-sequence-table"
                dataSource={categorySequences}
                columns={columns}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewCategorySequenceDetails;
