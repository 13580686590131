import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, InputNumber, Space, Switch } from "antd";
import { useState, useEffect } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty } from "../../Util/commonUtility";
import DynamicField from "./DynamicFields";

const AddGeneric = (props) => {
  const {
    catTypeid,
    type,
    onFinish,
    onFinishFailed,
    backToListPage,
    editGenericData,
    allCategoryTypes,
  } = props;
  const [defaultData, SetDefaultData] = useState(editGenericData);
  const [addGenericForm] = Form.useForm();
  const [isFormDataFetched, SetIsFormDataFetched] = useState(false);
  const [showLoading, SetShowLoading] = useState(false);
  const [formData, SetFormData] = useState();
  const [formID, SetFormID] = useState();

  useEffect(() => {
    getFormID();
    if (formID !== undefined) {
      fetchForm(getFormID());
    }
  }, [formID]);

  useEffect(() => {
    addGenericForm.setFieldsValue({
      ...editGenericData,
      valuePropName: editGenericData
        ? editGenericData.isPremium
          ? true
          : false
        : false,
    });

    addGenericForm.setFieldsValue({
      ...editGenericData,
      valuePropActive: editGenericData
        ? editGenericData.activeStatus === true
          ? true
          : false
        : false,
    });
  }, [editGenericData]);

  const getFormID = () => {
    SetFormID(allCategoryTypes.filter((obj) => obj.label === type)[0].value);
    return allCategoryTypes.filter((obj) => obj.label === type)[0].value;
  };

  const fetchForm = async () => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.CATEGORY_GET_CATEGORY_TYPE_BY_ID.replace(
        "#{categoryId}",
        formID
      )}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetFormData(response?.data.data);
      SetIsFormDataFetched(true);
    } else {
    }
    SetShowLoading(false);
  };

  return (
    <>
      <p className="add_temp_btn">
        {editGenericData ? " " + type : "Add " + type}
      </p>
      <Divider></Divider>
      <div className="add_box">
        <div className="add_cate_form" style={{ width: "40%" }}>
          <Form
            form={addGenericForm}
            className="common-form"
            name="add-product"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={defaultData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item hidden={true} name="_id">
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
              initialValue={
                editGenericData ? editGenericData.genericObject.name : ""
              }
            >
              <Input
                placeholder="Name"
                className="add_form"
                defaultValue={
                  editGenericData ? editGenericData.genericObject.name : ""
                }
              />
            </Form.Item>

            <Form.Item
              label="Sequence"
              name="sequence"
              rules={[
                {
                  required: false,
                  message: "Please enter Catergory sequence",
                },
              ]}
            >
              {editGenericData && (
                <InputNumber
                  className="add_form"
                  placeholder="Please enter sequence"
                  min={
                    editGenericData.genericObject.sequence === null ? null : 1
                  }
                  defaultValue={
                    editGenericData.genericObject.sequence === null
                      ? null
                      : editGenericData.genericObject.sequence
                  }
                  initialValue={
                    editGenericData.genericObject.sequence === null
                      ? null
                      : editGenericData.genericObject.sequence
                  }
                />
              )}

              {!editGenericData && (
                <InputNumber
                  className="add_form"
                  placeholder="Please enter sequence"
                  min={1}
                  defaultValue={1}
                  initialValue={1}
                />
              )}
            </Form.Item>

            <Form.Item
              label="Premium"
              name="isPremium"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                  message: "Please select template sub type",
                },
              ]}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>

            <Form.Item
              label="Active Status"
              name="activeStatus"
              valuePropActive="checkedActive"
              rules={[
                {
                  required: false,
                  message: "Please select Active Status",
                },
              ]}
            >
              {editGenericData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={editGenericData.activeStatus}
                />
              )}
              {!editGenericData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked="Yes"
                />
              )}
            </Form.Item>

            {isFormDataFetched && (
              <div>
                <DynamicField
                  formData={formData}
                  dataObject={editGenericData?.genericObject?.dataObject}
                />
              </div>
            )}

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Space>
                <Button
                  type="default"
                  icon={<ArrowLeftOutlined />}
                  className="ios-type-color"
                  onClick={() => {
                    backToListPage();
                  }}
                >
                  Back
                </Button>
                <Button
                  className="ios-type-color"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddGeneric;
