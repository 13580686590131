import React, { Fragment } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Notfound from "../Screens/ErrorPages/notFound";
import NotAuthorized from "../Screens/ErrorPages/notAuthorize";
import PrivateRoute from "../Screens/PrivateRoute";
import MainLayout from "../Components/Layout/mainLayout";
import Dashboard from "./../Screens/Dashboard";
import { matchPath } from "react-router";
import LoginScreen from "../Screens/Auth/login";
import Template from "../Screens/Templete";
import Category from "../Screens/Category";
import UserAdmin from "../Screens/UserAdmin";
import Environment from "../Screens/Environment/Environment";
import CategoryType from "../Screens/CategoryType";
import CategorySequenceScreen from "../Screens/CategorySequence";
import AddOrEditCategorySequence from "../Components/CategorySequence";
import ViewCategorySequenceDetails from "../Components/CategorySequence/viewDetailsComponent";

const AppRoute = () => {
  const match = useLocation();
  const checkResetPasswordToken = matchPath(
    { path: "/resetPassword/:token", exact: true, strict: true },
    match.pathname
  );
  return (
    <>
      <Fragment>
        {match.pathname === "/login" ||
        match.pathname === "/sendLink" ||
        checkResetPasswordToken ? (
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
          </Routes>
        ) : match.pathname === "/environment" ? (
          <Routes>
            <Route path="/environment" element={<Environment />} />
          </Routes>
        ) : (
          <MainLayout>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="category" element={<Category />} />
                <Route path="useradmin" element={<UserAdmin />} />
                <Route path="category/template" element={<Template />} />
                <Route path="category/sticker" />
                <Route path="category/type" element={<CategoryType />} />
                <Route path="useradmin" element={<UserAdmin />} />
                <Route
                  path="/category/sequence"
                  element={<CategorySequenceScreen />}
                />
                <Route
                  path="/categorySequenceForm/Add"
                  element={<AddOrEditCategorySequence isEdit={false} />}
                />
                <Route
                  path="/categorySequenceForm/Edit/:id"
                  element={<AddOrEditCategorySequence isEdit={true} />}
                />
                <Route
                  path="/categorySequenceForm/Details/:id"
                  element={<ViewCategorySequenceDetails />}
                />
              </Route>
              <Route path="*" element={<Notfound />} />
            </Routes>
          </MainLayout>
        )}
      </Fragment>
    </>
  );
};

export default AppRoute;
