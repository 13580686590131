import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/breadCrumb";
import { breadCrumbsPathForPages } from "../../Util/commonUtility";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  ExpandAltOutlined,
  ExportOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../Api/client";
import Urls from "../../Constant/Urls";
import moment from "moment";
import { HttpStatusCode } from "axios";
import NotificationService from "../../Services/notification";
import {
  DEFAULT_SEQUENCE_NAME,
  DefaultPagingValue,
} from "../../Constant/Common";
import TotalReocordsInListingComponent from "../../Components/Common/totalRecordListing";
import LocationJson from "../../Constant/ISO3.json";
import "./categorySequence.scss";
import { useForm } from "antd/es/form/Form";

const { Text } = Typography;
const { Option } = Select;

const LocationArray = LocationJson.map((loc) => {
  return <Option value={loc.code}>{`${loc.code} - ${loc.name}`}</Option>;
});

const campaignStatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Enabled",
    value: true,
  },
  {
    label: "Disabled",
    value: false,
  },
];

const CategorySequence = () => {
  const navigate = useNavigate();

  const [categoriesSequences, setCategoriesSequences] = useState([]);
  const [filterLoader, setFilterLoader] = useState(false);
  const [showLoading, SetShowLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [enableSaveStatus, setEnableSaveStatus] = useState("");
  const [openFilterForm, setOpenFilterForm] = useState(false);
  const [openChangeStatusPopover, setOpenChangeStatusPopover] = useState({
    id: "",
    open: false,
  });
  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  const [sequenceFilterForm] = useForm();

  const renderLocations = (locations, type) => {
    if (locations?.includes("ALL")) {
      return type ? (
        <>All locations are selected</>
      ) : (
        <Text code>All locations are selected</Text>
      );
    }
    return locations?.map((locationString) => {
      const currentLocation = LocationJson.find(
        (location) => location.code === locationString
      );

      if (type) {
        return (
          <span>
            {`${currentLocation?.code} - ${currentLocation?.name}`}, &nbsp;
          </span>
        );
      }
      return (
        <Text code>
          {`${currentLocation?.code} - ${currentLocation?.name}`}
        </Text>
      );
    });
  };

  useEffect(() => {
    if (!openChangeStatusPopover.open) {
      setEnableSaveStatus("");
    }
  }, [openChangeStatusPopover]);

  const editCategory = (data) => {
    navigate(`/categorySequenceForm/Edit/${data._id}`, {
      state: { mode: "Edit" },
    });
  };

  const viewCategorySequenceDetailsHandler = (rowRecord) => {
    navigate(`/categorySequenceForm/Details/${rowRecord._id}`);
  };

  const deleteCategorySequence = async (data) => {
    try {
      const response = await httpClient.delete(
        Urls.GET_CATEGORY_SEQUENCE.replace("#{sequenceId}", data._id)
      );
      if (response.status === HttpStatusCode.Ok) {
        await getCategorySequences();
        NotificationService.success(
          "Success",
          "Category Sequence Deleted Successfully"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const campaignCodeSelectEnums = {
    enabled: "Enabled",
    disabled: "Disabled",
  };

  const campaignCodeSelectOptions = [
    {
      label: campaignCodeSelectEnums.enabled,
      value: true,
    },
    {
      label: campaignCodeSelectEnums.disabled,
      value: false,
    },
  ];

  const changeCampaignStatusHandler = async (sequenceId) => {
    try {
      const response = await httpClient.put(
        Urls.CHANGE_SEQUENCE_STATUS.replace("#{sequenceId}", sequenceId),
        { isEnabled: currentStatus }
      );
      if (response.status === HttpStatusCode.Ok) {
        await getCategorySequences();
        NotificationService.success(
          "Success",
          "Category Sequence status updated successfully"
        );
        setOpenChangeStatusPopover(false);
      }
    } catch (error) {
      NotificationService.error("Error", "Error while changing status");
    }
  };

  const columns = [
    {
      dataIndex: "campaignCode",
      title: "Campaign Code",
      key: "Campaign Code",
      className: "text-start",
      render: (_, record) => (
        <>
          <Button className="category_name" type="link">
            {record.campaignCode}{" "}
            <ExpandAltOutlined title={`View ${record.campaignCode}`} />
          </Button>
        </>
      ),

      onCell: (record) => {
        <span>
          <ExportOutlined />
        </span>;
        return {
          onClick: () => {
            viewCategorySequenceDetailsHandler(record);
          },
        };
      },
    },
    {
      dataIndex: "location",
      title: "Locations",
      key: "location",
      className: "text-start",
      width: "40%",
      render: (data, _record) => (
        <>
          <div
            className="location-col-wrapper"
            style={{ maxHeight: "300px", overflow: "auto" }}
          >
            <Tooltip placement="topLeft" title={renderLocations(data, true)}>
              {renderLocations(data, false)}
            </Tooltip>
          </div>
        </>
      ),

      onCell: (record) => {
        <span>
          <ExportOutlined />
        </span>;
        return {
          onClick: () => {
            viewCategorySequenceDetailsHandler(record);
          },
        };
      },
    },
    {
      title: "Campaign",
      dataIndex: "isEnabled",
      key: "Campaign",
      render: (text) => (
        <Tag
          className="common-tag-color"
          color={text === true ? "green" : "red"}
        >
          {String(text ? "Enabled" : "Disabled")}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (text, record) => {
        return (
          <Space>
            <i
              className="bi bi-pencil-fill "
              title="Edit"
              onClick={() => {
                editCategory(record);
              }}
            ></i>
            {!(record.campaignCode === DEFAULT_SEQUENCE_NAME) && (
              <Popconfirm
                className="delete_pop_box "
                title="Are you sure to delete this category sequence?"
                onConfirm={() => {
                  deleteCategorySequence(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            )}
            <Tooltip
              color="geekblue"
              title={
                <div>
                  Created At :{"  "}
                  {moment(record.createdAt).format("DD/MMM/YY hh:mm A")}
                  <br />
                  Updated At :{"  "}
                  {moment(record.updatedAt).format("DD/MMM/YY hh:mm A")}{" "}
                </div>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
            {!(record.campaignCode === DEFAULT_SEQUENCE_NAME) && (
              <Popover
                key={record._id}
                prefixCls="change-status-popover"
                content={
                  <>
                    <Select
                      options={campaignCodeSelectOptions}
                      onChange={(status) => setCurrentStatus(status)}
                      onSelect={() => setEnableSaveStatus(record._id)}
                      defaultValue={
                        record.isEnabled
                          ? campaignCodeSelectEnums.enabled
                          : campaignCodeSelectEnums.disabled
                      }
                    />

                    <Button
                      className="add_btn save-status-btn"
                      disabled={!(record._id === enableSaveStatus)}
                      type="primary"
                      onClick={() => changeCampaignStatusHandler(record._id)}
                    >
                      Save
                    </Button>
                  </>
                }
                title="Change Campaign Status"
                trigger="click"
                open={
                  record._id === openChangeStatusPopover.id
                    ? openChangeStatusPopover.open
                    : false
                }
                onOpenChange={(newOpen) =>
                  setOpenChangeStatusPopover({ id: record._id, open: newOpen })
                }
              >
                <i
                  id={record._id}
                  key={record._id}
                  className="bi bi-three-dots-vertical "
                  title="Change Status"
                ></i>
              </Popover>
            )}
          </Space>
        );
      },
    },
  ];

  const getCategorySequences = async (filterFormValues) => {
    let location = "";
    let filterParams = "";

    if (filterFormValues) {
      location = filterFormValues?.location?.includes("ALL")
        ? "ALL"
        : filterFormValues.location;

      filterParams = `&campaignCode=${
        filterFormValues.campaignCode || ""
      }&location=${location || ""}&isEnabled=${filterFormValues.isEnabled}`;
    }
    try {
      SetShowLoading(true);
      const response = await httpClient.get(
        `${Urls.CREATE_CATEGORY_SEQUENCE}?page=${paging.pageIndex}&limit=${paging.pageSize}${filterParams}`
      );
      if (response.status === HttpStatusCode.Ok) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      setCategoriesSequences(response.data.data);
    } catch (error) {
      NotificationService.error(
        "Error",
        "Something went wrong wile getting Category Sequences"
      );
    } finally {
      SetShowLoading(false);
    }
  };

  useEffect(() => {
    getCategorySequences(sequenceFilterForm.getFieldsValue());
  }, [paging.pageIndex, paging.pageSize]);

  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };

  const onSelect = (_value, option) => {
    const allLocations = sequenceFilterForm.getFieldValue("location");

    if (
      option.value === "ALL" ||
      allLocations?.length === LocationJson.length
    ) {
      const allLocations = LocationJson.map((loc) => loc.code);
      sequenceFilterForm.setFieldsValue({ location: ["ALL", ...allLocations] });
    }
  };

  const onDeselect = (_value, option) => {
    const allLocations = sequenceFilterForm.getFieldValue("location");
    const isAllLocationsSelected = allLocations.includes("ALL");

    if (isAllLocationsSelected) {
      const filteredLocations = allLocations.filter((loc) => loc !== "ALL");
      sequenceFilterForm.setFieldsValue({ location: filteredLocations });
    }
    if (option.value === "ALL") {
      sequenceFilterForm.setFieldsValue({ location: [] });
    }
  };

  // added watch on form fields

  const campaignCodeWatch = Form.useWatch("campaignCode", sequenceFilterForm);
  const locationWatch = Form.useWatch("location", sequenceFilterForm);
  const campaignStatusWatch = Form.useWatch("isEnabled", sequenceFilterForm);

  // disable clear and save button for percticuler cases

  const disableClearButton =
    (!campaignCodeWatch &&
      !sequenceFilterForm.isFieldsTouched() &&
      !locationWatch &&
      campaignStatusWatch === undefined) ||
    showLoading;

  const disableSaveButton =
    (!campaignCodeWatch &&
      !locationWatch &&
      campaignStatusWatch === undefined) ||
    showLoading;

  const clearFilterFormHandler = () => {
    sequenceFilterForm.resetFields();
    getCategorySequences();
    const warningTxt = document.getElementById("closeFilterWarning");
    const filterFormId = document.getElementById("filter-form");
    if (warningTxt.style.display === "block") {
      warningTxt.style.display = "none";
      filterFormId.style.border = "1px solid #a09191";
    }
  };

  const showHideFilterContainer = () => {
    const warningTxt = document.getElementById("closeFilterWarning");
    const filterFormId = document.getElementById("filter-form");

    if (!disableClearButton) {
      warningTxt.style.display = "block";
      filterFormId.style.border = "1px solid red";
      return;
    }
    if (warningTxt && warningTxt.style.display === "block") {
      warningTxt.style.display = "none";
      filterFormId.style.border = "1px solid #a09191";
    }
    setOpenFilterForm(!openFilterForm);
  };

  return (
    <>
      <div className="category-sequence-container">
        <div className="bread-Crumb-Container mb-8">
          <div className="fs-name">
            <BreadCrumb
              breadCrumbItems={breadCrumbsPathForPages.categorySequence}
            />
          </div>
          <div className="buttons-wrapper">
            <Button
              className="add_btn"
              type="primary"
              onClick={() => navigate("/categorySequenceForm/Add")}
            >
              <PlusSquareOutlined />
              Add Category Sequence
            </Button>

            <Button className="filter_btn" onClick={showHideFilterContainer}>
              Filter and Search <FilterOutlined />
            </Button>
          </div>
        </div>
        {openFilterForm && (
          <div className="filter-sequence-form-wrapper" id="filter-form">
            <>
              <Form
                name="filterForm"
                form={sequenceFilterForm}
                onFinish={(formValues) => getCategorySequences(formValues)}
                // TODO: user cant close if filter is applied
              >
                <Form.Item name={"campaignCode"} noStyle>
                  <Input
                    placeholder="Campaign code"
                    className="filter_name increase-width"
                    allowClear
                  />
                </Form.Item>
                <Form.Item noStyle name="location">
                  <Select
                    className="cat-tem-input form-location-dropdown increase-width"
                    allowClear
                    // mode="multiple"
                    showSearch
                    placeholder="Select location"
                    maxTagCount={"responsive"}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>+ {omittedValues.length}</span>
                      </Tooltip>
                    )}
                  >
                    <Option value={"ALL"}>{`ALL - All locations`}</Option>
                    {LocationArray}
                  </Select>
                </Form.Item>

                <Form.Item noStyle name="isEnabled">
                  <Select
                    className="cat-tem-input form-location-dropdown increase-width"
                    allowClear
                    defaultValue={campaignStatusOptions[0].label}
                    options={campaignStatusOptions}
                    placeholder="Campaign Status"
                    maxTagCount={"responsive"}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>

                <Form.Item noStyle>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={disableSaveButton}
                  >
                    Search &nbsp; &nbsp; <i class="bi bi-search"></i>
                  </Button>
                </Form.Item>

                <Form.Item noStyle>
                  <Button
                    disabled={disableClearButton}
                    onClick={clearFilterFormHandler}
                    className="clear_btn"
                  >
                    Clear&nbsp; &nbsp;<i class="bi bi-x-square"></i>
                  </Button>
                </Form.Item>
              </Form>
              <span id="closeFilterWarning">
                *Please clear the current filter first.{" "}
              </span>
            </>
          </div>
        )}
        {/* <span id="closeFilterWarning">
          *Please clear the current filter first.{" "}
        </span> */}
        <div className="category-sequence-table-wrapper">
          <Table
            className="listCategory"
            loading={showLoading}
            dataSource={categoriesSequences.categorySequence || []}
            pagination={{
              pageSize: paging.pageSize,
              showSizeChanger: true,
              total: paging.totalPages * paging.pageSize,
              current: paging.pageIndex,
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showTotal: () => (
                <TotalReocordsInListingComponent
                  totalRecords={categoriesSequences.totalCount}
                />
              ),
            }}
            columns={columns}
            size="small"
          />
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default CategorySequence;
