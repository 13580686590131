import { DeleteOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Divider, Image, Popconfirm, Space, Table ,} from "antd";
import BreadCrumb from "../Common/breadCrumb";
import { breadCrumbsPathForPages } from "../../Util/commonUtility";
import TotalReocordsInListingComponent from "../Common/totalRecordListing";

const UserAdminListing = (props) => {
  const {
    dataSource,
    addUserAdmin,
    viewCategoryDetails,
    deleteUserAdmin,
    onFinish,
    paging,
    handlePageChange,
    editUserAdmin,
    totalRecords,
    editUserPassword,
  } = props;

  // const catClicked = (data) => {
  //   navigate("category", { state: { userA: data } });
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (text, record) => {
        return (
          <Space>
            <i
              class="bi bi-pencil-fill"
              title="Edit"
              onClick={() => {
                editUserAdmin(record);
              }}
            ></i>

            <i
              class="bi bi-person-fill-lock"
              title="Reset Password"
              onClick={() => {
                editUserPassword(record);
              }}
            ></i>

            <Popconfirm
              title="Are you sure to delete this User ?"
              onConfirm={() => {
                deleteUserAdmin(record);
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="bread-Crumb-Container">
        <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.user} />
        <Button
          className="add_btn"
          type="primary"
          onClick={() => {
            addUserAdmin();
          }}
        >
          <PlusSquareOutlined />
          Add User
        </Button>
      </div>
      <Table
        className="listUser"
        dataSource={dataSource}
        pagination={{
          pageSize: paging.pageSize,
          showSizeChanger: true,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent totalRecords={totalRecords} />
          ),
        }}
        columns={columns}
        rowKey={"_id"}
        size="small"
      />
    </>
  );
};
export default UserAdminListing;
