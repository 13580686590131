import React from "react";
import { Breadcrumb, Select } from "antd";
import { Link } from "react-router-dom";
import { breadCrumbsPathForPages } from "../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
const BreadCrumb = ({ breadCrumbItems, defaultValue, selection,onSelectionChange }) => {

  const navigate = useNavigate();
  const onProductSelectionChange=(data)=>{
    onSelectionChange(data)
  }  

  return (
    <Breadcrumb separator=">">
      {breadCrumbItems?.map((breadcrumb) => {
        return breadcrumb.name === "Product" ? (
          <Breadcrumb.Item key={breadcrumb}>
            <Select
              className="bread-crumb-selection"
              defaultValue={defaultValue}
              onChange={(data) => {
                onProductSelectionChange(data);
              }}
            >
              {selection?.map((prod, index) => (
                <Select.Option key={prod.id} value={prod.id}>
                  {prod.name}
                </Select.Option>
              ))}
            </Select>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={breadcrumb}>
            {breadcrumb.path ? (
              <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
            ) : (
              <div className="breadcrum_btn">{breadcrumb.name}</div>
            )}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumb;
