import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  allProducts: undefined,
  selectedProduct: undefined,
  allAllowedEnvs: undefined,
  selectedEnvironment: undefined,
};

export const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    setProductDetails(state, action) {
      state.allProducts = action.payload.allProducts;
      state.selectedProduct = action.payload.selectedProduct;
      state.selectedEnvironment =
        action.payload.selectedProduct.environments[0] ??
        action.payload.selectedProduct.environments[0];
      state.allAllowedEnvs = action.payload.allAllowedEnvs;
    },
    setSelectedEnvironmentDetails(state, action) {
      state.selectedEnvironment = state.selectedProduct.environments.find(
        (a) => a.id === action.payload.selectedEnvironmentId
      );
    },
  },
  extraReducers: (builder) => {},
});

export const { setProductDetails, setSelectedEnvironmentDetails } =
  productSlice.actions;

export default productSlice.reducer;
