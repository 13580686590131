const Urls = {
  ACCOUNT_LOGIN: "admin/login",
  ACCOUNT_REFRESHTOKEN: "token/refreshtoken",
  ACCOUNT_CHANGEPASSWORD: "accounts/#{accountId}/changepassword",
  ACCOUNT_PROFILE: "accounts/#{accountId}",

  GET_ALL_TEMPLATES: "template",
  TEMPLATE_DELETE_BY_ID: "template/#{templateID}",
  TEMPLATE_GET_TEMPLATE_BY_ID: "template/#{templateID}",
  TEMPLATE_GET_TEMPLATE_BY_CATEGORYID: "template/category/#{categoryId}",

  TEMPLATE_UPDATE_TEMPLATE_BY_ID: "template/#{templateID}",
  TEMPLATE_ADD_TEMPLATE: "template",

  DASHBOARD_GET_DASHBOARD_DATA: "admin/dashboard/analytics",

  STICKER_DELETE_BY_ID: "sticker/#{stickerID}",
  STICKER_GET_STICKER_BY_CATEGORYID: "sticker/category/#{categoryId}",
  STICKER_GET_STICKER_BY_ID: "sticker/#{stickerID}",
  STICKER_UPDATE_STICKER_BY_ID: "sticker/#{stickerID}",
  STICKER_ADD_STICKER: "sticker",

  GENERIC_GET_GENERIC_BY_CATEGORYID: "generic/category/#{Id}",
  GENERIC_GET_GENERICE_BY_ID: "generic/#{ID}",
  GENERIC_DELETE_BY_ID: "generic/#{ID}",
  GENERIC_UPDATE_GENERIC_BY_ID: "generic/#{ID}",
  GENERIC_ADD_GENERIC: "generic",

  TENANT_GET_PRODUCT_BY_ID: "tenants/#{tenantId}/products/#{productId}",
  TENANT_UPDATE_PRODUCT_BY_ID: "tenants/#{tenantId}/products/#{productId}",

  CATEGORY_GET_ALL_CATEGORIES: "categories",
  CATEGORY_ADD_CATEGORY: "categories",
  CATEGORY_GET_CATEGORY_BY_ID: "categories/#{categoryId}",
  CATEGORY_UPDATE_CATEGORY_BY_ID: "categories/#{categoryId}",
  CATEGORY_DELETE_CATEGORY_BY_ID: "categories/#{categoryId}",
  CATEGORY_LOGIN_BY_ID: "admin/login",

  CATEGORY_GET_ALL_CATEGORIES_TYPES: "categoryType/all",
  CATEGORY_UPDATE_CATEGOR_TYPE_BY_ID: "categoryType/#{categoryId}",
  CATEGORY_GET_CATEGORY_TYPE_BY_ID: "categoryType/#{categoryId}",
  CATEGORY_DELETE_CATEGORY_TYPE_BY_ID: "categoryType/#{categoryId}",
  CATEGORY_ADD_CATEGORY_TYPE: "categoryType",

  USER_GET_ALL_ADMINS: "admin/get/all",
  USER_ADD_ADMINS: "admin/create",
  USER_DELETE_ADMIN_BY_ID: "admin/#{adminId}",
  USER_EDIT_ADMIN_BY_ID: "admin/#{adminId}",
  USER_EDIT_ADMIN_BY_USER__ID: "admin/#{adminId}",
  USER_EDIT_PASSWORD_BY_ID: "admin/reset/#{adminId}",

  CREATE_CATEGORY_SEQUENCE: "categorySequence",
  GET_CATEGORY_SEQUENCE: "categorySequence/#{sequenceId}",
  CHANGE_SEQUENCE_STATUS: "/categorySequence/#{sequenceId}/status",
};
export default Urls;
