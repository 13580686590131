import {
  DeleteOutlined,
  FilterOutlined,
  PlusSquareOutlined,
  ExportOutlined,
  ExpandAltOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Space,
  Table,
  Tag,
  Form,
  Input,
  Select,
  Card,
  Col,
  Tooltip,
  Row,
} from "antd";
import moment from "moment";
import BreadCrumb from "../Common/breadCrumb";
import { breadCrumbsPathForPages, isObjEmpty } from "../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import TotalReocordsInListingComponent from "../Common/totalRecordListing";

const { Option } = Select;

const CategoryListing = (props) => {
  const {
    handleSort,
    handleFilter,
    onFilterFinish,
    onFilterClear,
    dataSource,
    addCategory,
    viewCategoryDetails,
    deleteCategory,
    fetchAllCategories,
    editCategory,
    paging,
    handlePageChange,
    filterData,
    categoriesTypeOptions,
    userAdmines,
    template,
    totalRecords,
    sortOrder,
    sortTitle,
    sortField,
    handleSearch,
    handleReset,
    searchInput,
    searchedText,
  } = props;
  const navigate = useNavigate();

  const catClicked = (data) => {
    navigate("template", {
      state: {
        categoryData: data,
        catTypeData: categoriesTypeOptions,
        userAdmines: userAdmines,
        template: template,
      },
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          defaultValue={searchedText}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys[0])}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    render: (text) => text,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // filters: [
      //   {
      //     text: "Sequence",
      //     value: "sequence",
      //   },
      //   {
      //     text: "Created At",
      //     value: "createdAt",
      //   },

      //   {
      //     text: "Active Status",
      //     value: "activeStatus",
      //   },
      //   {
      //     text: "Name",
      //     value: "name",
      //   },

      //   {
      //     text: "OS Type",
      //     value: "osType",
      //   },
      //   {
      //     text: "Category Type",
      //     value: "type",
      //   },
      // ],
      // filterMultiple: false,

      // onFilter: (value, record) => handleFilter(value, record),
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => {
          handleSort(
            column.dataIndex !== sortField ? column.dataIndex : sortField,
            sortOrder
          );
        },
      }),
      // ...getColumnSearchProps("name"),

      render: (_, record) => (
        <>
          <Button className="category_name" type="link">
            {record.name} <ExpandAltOutlined title={`Go to ${record.type}`} />
          </Button>
        </>
      ),
      onCell: (record, rowIndex) => {
        <span>
          <ExportOutlined />
        </span>;
        return {
          onClick: () => {
            catClicked(record);
          },
        };
      },
    },
    {
      title: "Active Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () =>
          handleSort(
            column.dataIndex !== sortField ? column.dataIndex : sortField,
            sortOrder
          ),
      }),
      render: (text) => (
        <Tag
          className="common-tag-color"
          color={text === true ? "green" : "red"}
        >
          {String(text ? "Yes" : "No")}
        </Tag>
      ),
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () =>
          handleSort(
            column.dataIndex !== sortField ? column.dataIndex : sortField,
            sortOrder
          ),
      }),
    },
    {
      title: "Category Type",
      dataIndex: "type",
      key: "type",

      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () =>
          handleSort(
            column.dataIndex !== sortField ? column.dataIndex : sortField,
            sortOrder
          ),
      }),
    },
    {
      title: "Sequence",
      dataIndex: "sequence",
      key: "sequence",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () =>
          handleSort(
            column.dataIndex !== sortField ? column.dataIndex : sortField,
            sortOrder
          ),
      }),
      render: (text) => {
        return text;
      },
    },
    {
      title: "OsType",
      dataIndex: "osType",
      key: "osType",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () =>
          handleSort(
            column.dataIndex !== sortField ? column.dataIndex : sortField,
            sortOrder
          ),
      }),
      render: (osType) => {
        return (
          <>
            {osType?.map((osType, index) => {
              return (
                <Tag key={index} className="common-tag-color" color="blue">
                  {" "}
                  {osType}{" "}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (text, record) => {
        return (
          <Space>
            <i
              class="bi bi-eye"
              title="Details"
              onClick={() => {
                catClicked(record);
              }}
            ></i>

            <i
              class="bi bi-pencil-fill"
              title="Edit"
              onClick={() => {
                editCategory(record);
              }}
            ></i>

            <Popconfirm
              className="delete_pop_box"
              title="Are you sure to delete this category ?"
              onConfirm={() => {
                deleteCategory(record);
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>
            <Tooltip
              color="geekblue"
              title={
                <>
                  Updated By :{"  "} {record.updatedBy} <br />
                  Created By :{"  "} {record.createdBy} <br />
                  Created At :{"  "}
                  {moment(record.createdAt).format("DD/MMM/YY hh:mm A")}
                  <br />
                  Updated At :{"  "}
                  {moment(record.updatedAt).format("DD/MMM/YY hh:mm A")}{" "}
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const [categoryListingForm] = Form.useForm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    categoryListingForm.setFieldsValue(filterData);
  }, [filterData]);

  useEffect(() => {
    const value = categoryListingForm.getFieldValue();
    if (value && filterData) {
      //setOpen(true);
    }
  }, []);

  // const onFinish = (data) => {
  //   onFilterFinish(data);
  // };
  const onFinish = (data) => {
    if (!isObjEmpty(data)) {
      onFilterFinish(data);
    }
  };
  const onClickClear = () => {
    onFilterClear();
  };

  const visibleSearch = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="bread-Crumb-Container">
        <div className="fs-name">
          <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.category} />
        </div>
        <Button
          className="add_btn"
          type="primary"
          onClick={() => {
            addCategory();
          }}
        >
          <PlusSquareOutlined />
          Add Category
        </Button>
      </div>

      <div className="filter">
        <Button className="filter_btn" onClick={visibleSearch}>
          Filter and Search <FilterOutlined />
        </Button>
      </div>
      {open ? (
        <Card className="filter_inside_box">
          <Form form={categoryListingForm} onFinish={onFinish}>
            <Row>
              <Col span={4}>
                <Form.Item name={"searchText"}>
                  <Input placeholder="Name" className="filter_name" />
                </Form.Item>
              </Col>

              <Col className="filter-dropdown" span={4}>
                <Form.Item name={"type"}>
                  <Select
                    placeholder="Category type "
                    className="cat-tem-input "
                    options={categoriesTypeOptions}
                  ></Select>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name={"osType"}>
                  <Select placeholder="Os Type" className="cat-tem-input ">
                    <Option value="ios">iOS</Option>
                    <Option value="android">Android</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    Search &nbsp; &nbsp; <i class="bi bi-search"></i>
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item>
                  <Button onClick={onClickClear} className="clear_btn">
                    Clear&nbsp; &nbsp;<i class="bi bi-x-square"></i>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={4}>
                <Form.Item
                  name={"sortField"}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && !getFieldValue("order")) {
                          return Promise.reject("Please select order field");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select placeholder="sortField" className="cat-tem-input ">
                    <Option value="sequence">Sequence</Option>
                    <Option value="createdAt">CreatedAt</Option>
                    <Option value="activeStatus">Active Status</Option>
                    <Option value="name">Name</Option>
                    <Option value="osType">Os Type</Option>
                    <Option value="type">Category type</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={"order"}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && !getFieldValue("sortField")) {
                          return Promise.reject("Please select sort field");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select placeholder="Order" className="cat-tem-input ">
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
          </Form>
        </Card>
      ) : (
        ""
      )}
      <Table
        className="listCategory"
        dataSource={dataSource}
        showSorterTooltip={{ title: `${sortTitle}` }}
        pagination={{
          pageSize: paging.pageSize,
          showSizeChanger: true,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent totalRecords={totalRecords} />
          ),
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              catClicked(record);
            }, // double click row
          };
        }}
        columns={columns}
        rowKey={"_id"}
        size="small"
      />
    </>
  );
};
export default CategoryListing;
