import React, { useEffect } from "react";
import "./mainLayout.scss";
import { Col, Dropdown, Layout, Row, Space, Tag } from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import { footerText } from "../../Util/config";
import { useDispatch, useSelector } from "react-redux";
import { accountLogout } from "../../Store/Reducers/Slices/Auth/authSlice";
import { isJson, isNullOrEmpty } from "../../Util/commonUtility";
const { Header, Content, Footer } = Layout;

const MainLayout = (props) => {
  const match = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product);
  const authState = useSelector((state) => state.auth);
  const localAccount = localStorage.getItem("account");
  const selEnv = localStorage.getItem("SelectedEnv");

  useEffect(() => {
    if (!localAccount && !selEnv) {
      navigate("/environment");
    } else if (window.location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [authState, selEnv, navigate]);

  const onMenuItemClick = (e) => {
    switch (e) {
      case "home":
        navigate("/");
        break;
      case "dashboard":
        navigate("/dashboard");
        break;

      case "logout":
        dispatch(accountLogout());
        navigate("/environment");
        break;
      case "settings":
        navigate("/settings");
        break;
      default:
        break;
    }
  };
  const getItem = (label, key, icon, children, type, checkForRole = false) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };
  const settingMenu = [
    getItem("My Account", "settings", <SettingOutlined />),
    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "logout",

      icon: <LogoutOutlined />,
    },
  ];
  const getUserLabel = () => {
    if (authState && authState.accountData && authState.accountData.email) {
      return authState.accountData.email;
    } else {
      return isJson(localAccount) ? JSON.parse(localAccount)?.email : "";
    }
  };

  return (
    <Layout className="layout">
      <Header className="layout-header">
        <Row className="header">
          <h1
            className="header_logo"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            BG Admin
          </h1>

          <Col xs={2} sm={4} md={4} lg={8} xl={8}>
            {localAccount && (
              <>
                <a
                  className="menu"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  Dashboard
                </a>

                <a
                  className="menu"
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  Category
                </a>

                <a
                  className="menu"
                  onClick={() => {
                    navigate("/category/type");
                  }}
                >
                  Category Type
                </a>

                <a
                  className="menu"
                  onClick={() => {
                    navigate("/category/sequence");
                  }}
                >
                  Category Sequence
                </a>

                {localAccount.includes("superadmin") && (
                  <a
                    className="user-menu"
                    onClick={() => {
                      navigate("/userAdmin");
                    }}
                  >
                    User
                  </a>
                )}
              </>
            )}
          </Col>
          <Col
            xs={2}
            sm={4}
            md={6}
            lg={8}
            xl={4}
            className="layout-header-menu-align-right"
          >
            <Space>
              <Tag color={selEnv === "devlopment" ? "#1677ff" : "volcano"}>
                {selEnv === "devlopment" ? "DEVELOPMENT" : "PRODUCTION"}
              </Tag>
            </Space>
            <Space>
              <Dropdown.Button
                menu={{
                  items: settingMenu,
                  onClick: (e) => onMenuItemClick(e.key),
                }}
                placement="bottom"
                icon={<UserOutlined />}
              >
                {getUserLabel()}
              </Dropdown.Button>
            </Space>
          </Col>
        </Row>
        {/* <Divider className="custom-divider" /> */}
        <hr></hr>
      </Header>
      <Content className="site-layout-content">
        <div className="site-layout-content-box">{props.children}</div>
      </Content>
      <Footer className="footer">
        {footerText} {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};
export default MainLayout;
