import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "./../../../../Api/client";
import Urls from "../../../../Constant/Urls";

export const accountLogin = createAsyncThunk(
  "admin/login",
  async (data, state) => {
    let routeToCall = Urls.ACCOUNT_LOGIN;
    const response = await httpClient.post(routeToCall, data);
    return response;
  }
);
export const getNewJwtToken = async (data, state) => {
  let routeToCall = Urls.ACCOUNT_REFRESHTOKEN;
  const response = await httpClient.post(routeToCall, { refreshToken: data });
  return response;
};
