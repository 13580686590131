import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Space } from "antd";
import Password from "antd/es/input/Password";
import { useEffect } from "react";

const AddUserAdmin = (props) => {
  const {
    onFinish,
    onFinishFailed,
    backToListPage,
    editUserData,
    resetUserPassword,
    isResetPassword,
    isAddUser,
    isEditUser,
  } = props;
  const [addUserForm] = Form.useForm();

  return (
    <>
      <p className="add_temp_btn">
        {" "}
        {isEditUser
          ? "Edit " + "User"
          : isAddUser
          ? "Add " + "User"
          : isResetPassword
          ? "Reset Password"
          : ""}
      </p>
      <Divider></Divider>
      <div className="add_cate_form">
        <Form
          form={addUserForm}
          className="common-form  add_user"
          name="add-UserData"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={
            isEditUser ? editUserData : isResetPassword ? resetUserPassword : ""
          }
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item hidden={true} name="_id">
            <Input />
          </Form.Item>

          {((!isResetPassword && isEditUser) || isAddUser) && (
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input admin name!",
                },
              ]}
            >
              <Input className="add_form" placeholder="Name" />
            </Form.Item>
          )}

          {((!isResetPassword && isEditUser) || isAddUser) && (
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input className="add_form" placeholder="Email" />
              {/* disabled={editUserData?true:false}  */}
            </Form.Item>
          )}
          {((isResetPassword && !isEditUser) || isAddUser) && (
            <Form.Item
              name={isResetPassword ? "newPassword" : "password"}
              label={isResetPassword ? "New Password" : "Password"}
              rules={[
                {
                  required: true,
                  type: Password,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Password" className="add_form" />
            </Form.Item>
          )}
          {((isResetPassword && !isEditUser) || isAddUser) && (
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={[isResetPassword ? "newPassword" : "password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value.length) {
                      return Promise.reject(
                        new Error(
                          "The new password lent sould be at least 6 character!"
                        )
                      );
                    }
                    if (
                      !value ||
                      getFieldValue(
                        isResetPassword ? "newPassword" : "password"
                      ) === value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                className="add_form"
              />
            </Form.Item>
          )}

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button
                className="add_form"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button className="add_form" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default AddUserAdmin;
