import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import BreadCrumb from "../Common/breadCrumb";
import { breadCrumbsPathForPages } from "../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import TotalReocordsInListingComponent from "../Common/totalRecordListing";
import { template } from "lodash";
import { useEffect } from "react";
const TemplateListing = (props) => {
  const {
    categoryType,
    dataSource,
    viewProduct,
    viewProductDetails,
    addGeneric,
    editGeneric,
    deleteGeneric,
    handlePageChange,
    paging,
    catTypeData,
    totalRecords,
    userAdmines,
    filterData,
    sortOrder,
    sortField,
    onFilterFinish,
    onFilterClear,
    sortTitle,
    handleSort,
    handleFilter,
    handleSearch,
    handleReset,
    searchInput,
    searchedText,
  } = props;

  const navigate = useNavigate();

  const catClicked = (data) => {
    navigate("template", {
      state: {
        templateData: data,
        catTypeData: catTypeData,
      },
    });
  };

  const getColumnsForGeneric = () => {
    let genericColumn = [];
    genericColumn = columns.filter(
      (item) => !(item.title === "Thumbnail Image")
    );

    return genericColumn;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          defaultValue={searchedText}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys[0])}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    render: (text) => text,
  });

  const columns = [
    {
      title: "Name",
      dataIndex:
        categoryType === "template"
          ? "name"
          : categoryType === "sticker"
          ? "name"
          : ["genericObject", "name"],
      key: "name",

      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
      ...getColumnSearchProps("name"),
      render: (name) => (
        <Tooltip
          placement="right"
          color="skyblue"
          title={"Go To Template " + name}
        >
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Premium",
      dataIndex: "isPremium",
      key: "isPremium",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
      render: (text) => String(text ? "Yes" : "No"),
    },
    {
      title: "Active Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
      render: (text) => (
        <Tag
          className="common-tag-color"
          color={text === true ? "green" : "red"}
        >
          {String(text ? "Yes" : "No")}
        </Tag>
      ),
    },
    {
      title: "Thumbnail Image",
      dataIndex: "thumbnailImage",
      key: "thumbnailImge",
      render: (thumbnail) => <Image className="templ_img" src={thumbnail} />,
    },
    {
      title: "Sequence",
      dataIndex:
        categoryType === "template"
          ? "sequence"
          : categoryType === "sticker"
          ? "sequence"
          : ["genericObject", "sequence"],
      key: "sequence",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            <i
              class="bi bi-pencil-fill"
              title="Edit"
              onClick={() => {
                editGeneric(record);
              }}
            ></i>

            <Popconfirm
              title={"Are you sure to delete this " + categoryType + "?"}
              onConfirm={() => {
                deleteGeneric(record);
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>

            <Tooltip
              color="geekblue"
              placement="topLeft"
              title={
                <>
                  Updated By : {"  "} {record.updatedBy}
                  <br />
                  Created By :{"  "} {record.createdBy}
                  <br />
                  Created At :{"   "}
                  {
                    moment(record.createdAt).format("DD/MMM/YY hh:mm A")
                    // .format("DD/MMM/YY kk:mm A")}{" "}
                  }
                  <br />
                  Updated At :{"  "}
                  {moment(record.updatedAt).format("DD/MMM/YY hh:mm A")} <br />
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <div className="bread-Crumb-Container">
        {categoryType === "sticker" ? (
          <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.sticker} />
        ) : categoryType === "template" ? (
          <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.template} />
        ) : (
          <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.generic} />
        )}

        <div>
          {
            <Button
              type="primary"
              className="common-button"
              onClick={() => {
                addGeneric();
              }}
            >
              <PlusSquareOutlined />
              {categoryType === "template"
                ? "Add Template"
                : categoryType === "sticker"
                ? "Add Sticker"
                : "Add " + categoryType}
            </Button>
          }
        </div>
      </div>
      <Table
        showSorterTooltip={{ title: `${sortTitle}` }}
        className="listCategory"
        dataSource={
          categoryType === "template"
            ? dataSource.result
            : categoryType === "sticker"
            ? dataSource.findStickers
            : dataSource
        }
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={
                categoryType === "template"
                  ? dataSource.totalCount
                  : categoryType === "sticker"
                  ? dataSource.totalCount
                  : totalRecords
              }
            />
          ),
          pageSize: paging.pageSize,
          showSizeChanger: true,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={
          categoryType === "template"
            ? columns
            : categoryType === "sticker"
            ? columns
            : getColumnsForGeneric()
        }
        rowKey={"_id"}
        size="small"
      />
    </>
  );
};
export default TemplateListing;