import { ArrowLeftOutlined, LeftCircleFilled } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { CATEGORY_TYPE, OS_TYPE, ORDER } from "../../Constant/DropDown";
import { useEffect, useState } from "react";
import LocationJson from "../../Constant/ISO3.json";

const { Option } = Select;

const LocationArray = LocationJson.map((loc) => {
  return <Option value={loc.code}>{`${loc.code} - ${loc.name}`}</Option>;
});

const locationArrayLength = LocationJson.length;

const AddCategory = (props) => {
  const {
    onFinish,
    onFinishFailed,
    backToListPage,
    editCategoryData,
    categoriesTypeOptions,
  } = props;
  const [defaultData, SetDefaultData] = useState(editCategoryData);
  const [addCategoryForm] = Form.useForm();

  const ImagePreview = ({ imageUrl }) => {
    return imageUrl ? <Image width={140} height={120} src={imageUrl} /> : null;
  };

  const onImageUrlChange = (e) => {
    SetDefaultData({
      ...defaultData,
      thumbnailImage: e.target.value,
    });
  };

  useEffect(() => {
    if (editCategoryData?.location?.includes("ALL")) {
      const allLocations = LocationJson?.map((loc) => loc.code);
      addCategoryForm.setFieldsValue({ location: ["ALL", ...allLocations] });
    }
  }, [editCategoryData]);

  const onSelect = (_value, option) => {
    const allLocations = addCategoryForm.getFieldValue("location");

    if (option.value === "ALL" || allLocations.length === locationArrayLength) {
      const allLocations = LocationJson.map((loc) => loc.code);
      addCategoryForm.setFieldsValue({ location: ["ALL", ...allLocations] });
    }
  };

  const onDeselect = (_value, option) => {
    const allLocations = addCategoryForm.getFieldValue("location");
    const isAllLocationsSelected = allLocations.includes("ALL");

    if (isAllLocationsSelected) {
      const filteredLocations = allLocations.filter((loc) => loc !== "ALL");
      addCategoryForm.setFieldsValue({ location: filteredLocations });
    }
    if (option.value === "ALL") {
      addCategoryForm.setFieldsValue({ location: [] });
    }
  };

  return (
    <>
      <p className="add_temp_btn">
        {editCategoryData ? "Edit" : "Add"} Category
      </p>
      <Divider></Divider>
      <div className="add_box">
        <div className="add_cate_form">
          <Form
            form={addCategoryForm}
            className="common-form"
            name="add-product"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={defaultData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item hidden={true} name="_id">
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name!",
                },
              ]}
            >
              <Input placeholder="Name" className="add_form" />
            </Form.Item>

            <Form.Item
              label="Active Status"
              name="activeStatus"
              valuePropActive="checkedActive"
              rules={[
                {
                  required: false,
                  message: "Please select Active Status",
                },
              ]}
            >
              {editCategoryData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={editCategoryData.activeStatus}
                />
              )}
              {!editCategoryData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked="Yes"
                />
              )}
            </Form.Item>

            <Form.Item
              label="Sequence"
              name="sequence"
              rules={[
                {
                  required: false,
                  message: "Please enter Catergory sequence!",
                },
              ]}
            >
              {editCategoryData && (
                <InputNumber
                  className="add_form"
                  placeholder="Please enter template sequence"
                  min={editCategoryData.sequence === null ? null : 1}
                  defaultValue={editCategoryData.sequence === null ? null : 1}
                  initialValue={editCategoryData.sequence === null ? null : 1}
                />
              )}

              {!editCategoryData && (
                <InputNumber
                  className="add_form"
                  placeholder="Please enter template sequence"
                  min={1}
                  defaultValue={1}
                  initialValue={1}
                />
              )}
            </Form.Item>

            {/* <Form.Item
                  label="Sequence"
                  name="sequence"
                  rules={[ 
                    {
                      required: false,
                      message: "Please input template sequence!",
                    },
                  ]}
                >
                  { editCategoryData && (
                    <InputNumber
                      placeholder="Please enter template sequence"
                      min={ editCategoryData.sequence === null ? null : 1}
                      defaultValue={
                        editCategoryData.sequence === null ? null : 1
                      }
                      initialValue={
                        editCategoryData.sequence === null ? null : 1
                      }
                    />
                  )}

                  {! editCategoryData && (
                    <InputNumber
                      placeholder="Please enter template sequence"
                      min={1}
                      defaultValue={1}
                      initialValue={1}
                    />
                  )}
                </Form.Item> */}

            <Form.Item
              label="Thumbnail Image URL"
              name="thumbnailImage"
              rules={[
                {
                  required: false,
                  message: "Please enter the thumbnailImage URL!",
                },
                {
                  type: "url",
                  warningOnly: true,
                  message: "Please enter valid url",
                },
              ]}
            >
              <Input
                placeholder="Image URL"
                onChange={(event) => {
                  onImageUrlChange(event);
                }}
                className="add_form"
              />
            </Form.Item>
            <Form.Item
              label="Os Type"
              name="osType"
              rules={[
                {
                  required: true,
                  message: "Please select Os Type!",
                },
              ]}
            >
              <Select
                className="ios-type-color"
                mode="multiple"
                allowClear
                placeholder="Please select ostype"
                options={OS_TYPE}
              />
            </Form.Item>
            {!editCategoryData && (
              <Form.Item
                label="Category Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please enter the thumbnailImage!",
                  },
                ]}
              >
                <Select
                  className="ios-type-color"
                  allowClear
                  placeholder="Please select ostype"
                  options={categoriesTypeOptions}
                />
              </Form.Item>
            )}
            {editCategoryData && (
              <Form.Item
                label="Category Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please enter the thumbnailImage!",
                  },
                ]}
              >
                <Select
                  className="ios-type-color"
                  disabled
                  allowClear
                  placeholder="Please select ostype"
                  options={categoriesTypeOptions}
                />
              </Form.Item>
            )}

            <Form.Item
              label="Location"
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please enter the locations",
                },
              ]}
            >
              <Select
                className="ios-type-color"
                allowClear
                mode="multiple"
                placeholder="Please select location"
                maxTagCount="responsive"
                onSelect={onSelect}
                onDeselect={onDeselect}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{
                      pointerEvents: "none",
                    }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>+ {omittedValues.length}</span>
                  </Tooltip>
                )}
              >
                <Option value={"ALL"}>{`ALL - All locations`}</Option>
                {LocationArray}
              </Select>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Space>
                <Button
                  type="default"
                  icon={<ArrowLeftOutlined />}
                  className="ios-type-color"
                  onClick={() => {
                    backToListPage();
                  }}
                >
                  Back
                </Button>
                <Button
                  className="ios-type-color"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
        <div className="add_side_img">
          <Form.Item className="add_img_width">
            <Image src={defaultData?.thumbnailImage} />
          </Form.Item>
        </div>
      </div>
    </>
  );
};
export default AddCategory;
