import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import EnvironmentComponent from "../../Components/Environment/Environment";

const EnvironmentContainer = () => {
  //#region hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region selector
  const selEnv = localStorage.getItem("SelectedEnv");
  const localAccount = localStorage.getItem("account");
  const authState = useSelector((state) => state.auth);
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (localAccount) {
      navigate("/dashboard");
    } else {
      if (selEnv) {
        navigate("/login");
      } else {
        navigate("/environment");
      }
    }
  }, [selEnv, localAccount, navigate]);

  return <EnvironmentComponent />;
};
export default EnvironmentContainer;
