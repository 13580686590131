import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Role } from "../../Constant/Common";
import { isJson } from "../../Util/commonUtility";

const PrivateRoute = () => {
  const selEnv = localStorage.getItem("SelectedEnv");
  const accountdata = localStorage.getItem("account");
  const localAccount = isJson(accountdata) ? JSON.parse(accountdata) : null;
  const auth = !!localAccount; // Determine if authorized, from context or however you're doing it
  if (auth) {
    return localAccount.role.includes(Role.SUPERADMIN) ||
      localAccount.role.includes(Role.ADMIN) ? (
      <Outlet />
    ) : (
      <Navigate to="/not-authorize" />
    );
  } else {
    if (selEnv != null) {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/environment" />;
    }
  }
};

export default PrivateRoute;
