import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { isNullOrEmpty, objectToQueryString } from "../../Util/commonUtility";
import { DefaultPagingValue } from "../../Constant/Common";
import AddCategoryType from "../../Components/CategoryType/addCategoryType";
import CategoryTypeListing from "../../Components/CategoryType/categoryTypeListing";
import MessageNotification from "../../Components/Common/messageNotification";

const CategoryTypeContainer = (route, navigation) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoading, SetShowLoading] = useState(false);
  const [isAddEditCategoryType, SetIsAddEditCategoryType] = useState(false);
  const [categories, SetCategories] = useState([]);
  const [categoriesType, SetCategoriesType] = useState([]);
  const [editCategoryTypeData, SetEditCategoryTypeData] = useState();
  const [categoriesTypeOptions, SetCategoriesTypeOptions] = useState([]);
  const [totalRecords, SetTotalRecords] = useState("");

  const [filterData, setFilterData] = useState(undefined);
  const [searchData, setSearchData] = useState();
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortTitle, setSortTitle] = useState("Click to Sort Descending");
  const searchInput = useRef(null);

  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  useEffect(() => {
    fetchAllCategoriesTypes();
  }, [paging.pageIndex, paging.pageSize, filterData]);

  const submitAddCategoryTypeForm = async (data) => {
    SetShowLoading(true);
    if (data._id) {
      const response = await httpClient.put(
        Urls.CATEGORY_UPDATE_CATEGOR_TYPE_BY_ID.replace(
          "#{categoryId}",
          data._id
        ),
        data
      );
      if (isSuccessStatus(response?.status)) {
        MessageNotification("success", response.data.message);
        resetEditFormState();
        fetchAllCategoriesTypes();
      } else {
      }
    } else {
      let newData = data;
      newData.fields.forEach((element) => {
        if (element.isRequired === undefined) {
          element.isRequired = true;
        }
      });
      if (newData.activeStatus === undefined) {
        newData.activeStatus = true;
      }
      const response = await httpClient.post(
        Urls.CATEGORY_ADD_CATEGORY_TYPE,
        newData
      );
      if (isSuccessStatus(response?.status)) {
        MessageNotification("success", response.data.message);
        resetEditFormState();
        fetchAllCategoriesTypes();
      } else {
      }
    }

    SetIsAddEditCategoryType(false);
    SetShowLoading(false);
  };

  const fetchAllCategoriesTypes = async (data) => {
    SetShowLoading(true);
    let filterDatas = "";
    if (filterData) {
      filterDatas = objectToQueryString(filterData);
    }
    const response = await httpClient.get(
      // `${Urls.CATEGORY_GET_ALL_CATEGORIES_TYPES}`
      `${Urls.CATEGORY_GET_ALL_CATEGORIES_TYPES}?page=${paging.pageIndex}
    &limit=${paging.pageSize}&${filterDatas && filterDatas}`
    );

    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetCategoriesType(response?.data.data.findCategoryTypes);
      SetTotalRecords(response?.data.data.totalCount);
      if (response?.data.data.result) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
    }
    SetShowLoading(false);
  };

  const deleteCategoryType = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.CATEGORY_DELETE_CATEGORY_TYPE_BY_ID.replace(
        "#{categoryId}",
        data._id
      )}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification("success", response.data.message);
      fetchAllCategoriesTypes();
    } else {
    }
    SetShowLoading(false);
  };

  const editCategoryType = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.CATEGORY_GET_CATEGORY_TYPE_BY_ID.replace(
        "#{categoryId}",
        data._id
      )}`
    );
    if (isSuccessStatus(response?.status)) {
      // messageApi.open({
      //   type: "success",
      //   content: response.data.message,
      //   duration: 2,
      // });
      MessageNotification("success", response.data.message);

      SetIsAddEditCategoryType(true);
      SetEditCategoryTypeData(response?.data?.data);
    } else {
    }
    SetShowLoading(false);
  };

  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };
  const resetEditFormState = () => {
    SetIsAddEditCategoryType(false);
    SetEditCategoryTypeData(null);
  };

  const onFilterFinish = (data) => {
    setFilterData(data);
  };

  const toggleSortOrder = (order) => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const handleFilter = (value, record) => {
    setSortField(value);
    let data = {
      searchText: "",
      sortField: sortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };
  const handleSort = (column, sortOrderNew) => {
    if (sortOrderNew === "desc") {
      setSortTitle("Click to Sort Ascending");
    } else {
      setSortTitle("Click to Sort Descending");
    }
    toggleSortOrder(sortOrder);
    setSortField(column);

    let data = {
      searchText: "",
      sortField: column,
      order: sortOrder,
    };
    onFilterFinish(data);
  };

  const handleSearch = (searchText) => {
    setSearchData(searchText);

    let data = {
      searchText: searchText,
      sortField: sortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };

  const handleReset = () => {
    setFilterData(undefined);
    setSearchData(undefined);
  };
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : isAddEditCategoryType ? (
        <AddCategoryType
          onFinish={(data) => {
            submitAddCategoryTypeForm(data);
          }}
          editCategoryTypeData={editCategoryTypeData}
          backToListPage={() => resetEditFormState()}
        />
      ) : (
        <CategoryTypeListing
          dataSource={categoriesType}
          onFilterFinish={(data) => onFilterFinish(data)}
          addCategory={() => SetIsAddEditCategoryType(true)}
          editCategory={(data) => editCategoryType(data)}
          deleteCategory={(data) => deleteCategoryType(data)}
          paging={paging}
          totalRecords={totalRecords}
          handlePageChange={(page, pageSize) => {
            handlePageChange(page, pageSize);
          }}
          filterData={filterData}
          sortOrder={sortOrder}
          sortField={sortField}
          sortTitle={sortTitle}
          handleSort={(column, sortOrderNew) =>
            handleSort(column, sortOrderNew)
          }
          handleFilter={(value, record) => handleFilter(value, record)}
          searchedText={searchData}
          handleSearch={(searchText) => handleSearch(searchText)}
          handleReset={(clearFilters) => handleReset(clearFilters)}
          searchInput={searchInput}
        />
      )}
    </>
  );
};

export default CategoryTypeContainer;
