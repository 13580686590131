import { useDispatch, useSelector } from "react-redux";
import LoginComponent from "../../Components/Auth/login";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { isNullOrEmpty, openMessage } from "../../Util/commonUtility";
import { accountLoginRequest } from "../../Store/Reducers/Slices/Auth/authSlice";
import NotificationService from "./../../Services/notification";
import { message, notification } from "antd";
import { ClockCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import messageNotification from "../../Components/Common/messageNotification";
import MessageNotification from "../../Components/Common/messageNotification";

const LoginContainer = () => {
  //#region hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region selector
  const localAccount = localStorage.getItem("account");
  const selEnv = localStorage.getItem("SelectedEnv");
  const authState = useSelector((state) => state.auth);
  const [showLoading, SetShowLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  //#endregion

  //#region useEffect
  useEffect(() => {
    if (localAccount) {
      navigate("/dashboard");
    } else {
      if (selEnv) {
        navigate("/login");
      } else {
        navigate("/environment");
      }
    }
  }, [authState, selEnv, localAccount, navigate]);
  //#endregion

  //#region function
  const onFinish = async (values) => {
    try {
      SetShowLoading(true);
      await httpClient
        .post(Urls.ACCOUNT_LOGIN, values)
        .then((response) => {
          if (
            !isNullOrEmpty(response) &&
            !isNullOrEmpty(response?.data) &&
            isSuccessStatus(response?.status)
          ) {
              dispatch(accountLoginRequest(response.data));
          }
        })
        .catch((error) => {
          MessageNotification(
            "error",
            error?.response?.data?.payload?.message
          );
        });
      SetShowLoading(false);
    } catch (error) {}
  };

  return (
    <>
      {} <LoginComponent onFinish={onFinish} showLoading={showLoading} />
    </>
  );

  //#endregion
};
export default LoginContainer;
