import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = (
  <LoadingOutlined   className="loader"
    spin
  />
);

const Loader = () => (
  <div className="loader-overlay">
    <Spin indicator={antIcon} />
  </div>
);

export default Loader;
