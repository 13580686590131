import { notification, message } from "antd";

export const isEmpty = (value) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty("length") && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const isObjEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] !== undefined) {
      return false;
    }
  }
  return true;
};

export const breadCrumbsPathForPages = {
  dashboard: [{ path: "", name: "Dashboard" }],
  user: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "", name: "User" },
  ],
  category: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "", name: "Category" },
  ],

  categoryType: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "", name: "Category Type" },
  ],

  categorySequence: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "", name: "category Sequence" },
  ],

  Edit: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "../category/sequence", name: "Category Sequence" },
    { path: "", name: "Edit Category Sequence" },
  ],
  Add: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "../category/sequence", name: "Category Sequence" },
    { path: "", name: "Add Category Sequence" },
  ],
  Details: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "../category/sequence", name: "Category Sequence" },
    { path: "", name: "Details" },
  ],

  template: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "../category", name: "Category" },
    { path: "", name: "Template" },
  ],
  generic: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "../category", name: "Category" },
    { path: "", name: "Generic" },
  ],
  sticker: [
    { path: "../dashboard", name: "Dashboard" },
    { path: "../category", name: "Category" },
    { path: "", name: "Sticker" },
  ],
};

export const isNullOrEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" && value.trim() === "") {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

export const isJson = (jsonstring) => {
  try {
    JSON.parse(jsonstring);
    return true;
  } catch (error) {
    return false;
  }
};

export const colorCodeBasedOnEnvironemnt = (env) => {
  switch (env) {
    case "production":
      return "#f5222d";
    case "qa":
      return "#5cdbd3";
    case "development":
      return "#ffc069";
    case "staging":
      return "#bae637";
    default:
      return "#69b1ff";
  }
};
export const colorCodeBasedOnRole = (env) => {
  switch (env) {
    case "Admin":
      return "#f5222d";
    case "User":
      return "#bae637";
    case "CICDUSER":
      return "#69b1ff";
    default:
      return "#69b1ff";
  }
};

export const getLocalStorageAccountInfo = () => {
  const localAccount = localStorage.getItem("account");
  if (isJson(localAccount)) {
    return JSON.parse(localAccount);
  } else {
    return undefined;
  }
};
export const setLocalStorageAccountInfo = (data) => {
  localStorage.setItem("account", JSON.stringify(data));
  localStorage.setItem("accessToken", data?.token);
};
export const removeLocalStorageAccountInfo = () => {
  localStorage.removeItem("account");
  localStorage.removeItem("token");
  localStorage.removeItem("SelectedEnv");
};

// export const openNotification = (type, message, description) => {
// notification.open({
//   type,
//   message,
//   description
// })
// }

// const [messageApi, contextHolder] = message.useMessage();
// export const openMessage = (type, content, duration) => {
//   console.log({type, content, duration});
//   messageApi.open({
//     type,
//     content,
//     duration
//   })
//   }

export const objectToQueryString = (obj) => {
  var output = JSON.parse(JSON.stringify(obj));
  const keys = Object.keys(output);
  const keyValuePairs = keys.map((key) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join("&");
};
