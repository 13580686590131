import {
  ArrowLeftOutlined,
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Space, Tag } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LoginComponent = ({ onFinish, showLoading }) => {
  const authState = useSelector((state) => state.auth);
  const localAccount = localStorage.getItem("account");
  const selEnv = localStorage.getItem("SelectedEnv");
  const navigate = useNavigate();

  const goToEnvPage = () => {
    localStorage.removeItem("SelectedEnv");
    navigate("/environment");
  };

  return (
    <div className="box_login">
      <div className="login_page">
        <Row justify="center">
          <Col className="login-card" span={6}>
            <Card
              className="login_page_card"
              title={
                <>
                  <div className="logo">
                    <h2>BG Admin</h2>
                    <Space style={{ marginLeft: "20px", paddingBottom: "7px" }}>
                      <sub>
                        <Tag
                          color={
                            selEnv === "devlopment" ? "#1677ff" : "volcano"
                          }
                        >
                          {selEnv === "devlopment"
                            ? "DEVELOPMENT"
                            : "PRODUCTION"}
                        </Tag>
                      </sub>
                    </Space>
                  </div>
                </>
              }
            >
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  username: "supperadmin",
                  email: "",
                  password: "",
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="password"
                  />
                </Form.Item>
                <Form.Item className="text-center">
                  <Space className="login-form-space">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={showLoading}
                      message="Please enter your Password!"
                      icon={showLoading ? <LoadingOutlined /> : ""}
                    >
                      Log in
                    </Button>
                  </Space>
                  <Space
                    className="login-form-space"
                    style={{ marginTop: "10px" }}
                  >
                    <Button
                      type="default"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => {
                        goToEnvPage();
                      }}
                    >
                      Back
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginComponent;
