import axios from "axios";
import utilConfig from "../Util/config";
import {
  getLocalStorageAccountInfo,
  isNullOrEmpty,
  removeLocalStorageAccountInfo,
  setLocalStorageAccountInfo,
} from "../Util/commonUtility";
import { getNewJwtToken } from "../Store/Reducers/Slices/Auth/authAction";
import {
  HTTP_ACCEPTED,
  HTTP_CREATED,
  HTTP_NO_CONTENT,
  HTTP_OK,
  HTTP_UNAUTHORIZED,
} from "../Constant/HttpStatus";
import NotificationService from "../Services/notification";
const envSel = localStorage.getItem("SelectedEnv");
const getMyToken = () => {
  const accessToken = localStorage.getItem("token");
  if (accessToken) {
    return accessToken;
  } else {
    return "";
  }
};

export const httpClient = axios.create({
  // baseURL: envSel === "production" ? config.apiUrlProd : config.apiUrlDev,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use(
  async (config) => {
    config.baseURL =
      localStorage.getItem("SelectedEnv") === "production"
        ? utilConfig.apiUrlProd
        : utilConfig.apiUrlDev;
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    // async (config) => {
    //   const accessToken = localStorage.getItem("accessToken");
    //   if (accessToken) {
    //     config.headers.Authorization = `Bearer ${accessToken}`;
    //   }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);
let isRefreshTokenFetching = false;
let failedApis = [];
// Response interceptor
// httpClient.interceptors.response.use(
//   (response) => {
//     // Modify the response data before it is used
//     // For example, you can transform the response data
//     return response;
//   },
//   async (error) => {
//     const account = await getLocalStorageAccountInfo();
//     try {
//       const originalRequest = error.config;
//       if (
//         error.response.status === HTTP_UNAUTHORIZED &&
//         !originalRequest?._retry
//       ) {
//         originalRequest._retry = true;
//         if (!isRefreshTokenFetching) {
//           failedApis.push(originalRequest);
//           isRefreshTokenFetching = true;
//           const response = await getNewJwtToken(account.refreshToken);
//           if (response.data.errorCode) {
//             throw response;
//           }
//           if (response.data) {
//             const { accessToken, refreshToken } = response.data;
//             account.accessToken = accessToken;
//             account.refreshToken = refreshToken;
//             setLocalStorageAccountInfo(account);

//             if (failedApis.length > 0) {
//               const failedApisCall = failedApis.map((api) => httpClient(api));
//               await Promise.all(failedApisCall);
//               isRefreshTokenFetching = false;
//               failedApis = [];
//             }
//           }
//         }
//         // handle error: inform user, go to login, etc
//       } else {
//         failedApis.push(originalRequest);
//         if (!isNullOrEmpty(error?.response?.data)) {
//           NotificationService.error(
//             error.response?.data?.code,
//             error.response.data.message
//           );
//           if (error.response.data?.errors?.length > 0) {
//             error.response.data?.errors.map((err) => {
//               NotificationService.error("Error", err.message);
//             });
//           }
//         } else {
//           return Promise.reject(error);
//         }
//       }
//     } catch (error) {
//       removeLocalStorageAccountInfo();
//       window.location.href = "/login";
//       // return Promise.reject(error);
//     }

//     // Handle response error
//     // return Promise.reject(error);
//   }
// );

export const isSuccessStatus = (status) =>
  [HTTP_OK, HTTP_CREATED, HTTP_NO_CONTENT, HTTP_ACCEPTED].includes(status);
