import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import { HTML_TYPE, HTML_TYPE_DEFAULT_VALUE } from "../../Constant/DropDown";

const AddCategoryType = (props) => {
  const { onFinish, onFinishFailed, backToListPage, editCategoryTypeData } =
    props;
  const [defaultData, SetDefaultData] = useState(editCategoryTypeData);
  const [showLoading, setShowLoading] = useState(true);
  const [defaultFieldData, setDefaultFieldData] = useState({
    htmltype: "",
    fields: [],
  });
  const [addCategoryTypeForm] = Form.useForm();

  useEffect(() => {
    if (addCategoryTypeForm.getFieldsValue()) {
      setShowLoading(false);
    }
  }, [editCategoryTypeData]);

  useEffect(() => {
    addCategoryTypeForm.setFieldsValue({
      ...editCategoryTypeData,
      valuePropNameIsReq: editCategoryTypeData
        ? editCategoryTypeData.fields.isRequired === true
          ? true
          : false
        : true,
    });

    addCategoryTypeForm.setFieldsValue({
      ...editCategoryTypeData,
      valuePropActiveType: editCategoryTypeData
        ? editCategoryTypeData.activeStatus === true
          ? true
          : false
        : true,
    });

    if (editCategoryTypeData?.fields) {
      setDefaultFieldData({
        ...defaultFieldData,
        fields: editCategoryTypeData.fields,
      });
    }
  }, [editCategoryTypeData]);

  const onHtmlTypeChange = (e, action = "field", index) => {
    setShowLoading(true);
    if (action === "htmlType") {
      setDefaultFieldData({
        ...defaultFieldData,
        htmltype: e,
        fields: addCategoryTypeForm.getFieldValue("fields"),
      });
      setShowLoading(false);
    } else {
      setShowLoading(false);
    }
  };

  useEffect(() => {}, [defaultFieldData]);

  const fields = () => {
    return (
      <>
        <Form.List
          name="fields"
          rules={[
            {
              validator: async (_, fields) => {
                if (!fields || fields.length < 1) {
                  return Promise.reject(new Error("At least 1 field needed"));
                }
              },
            },
          ]}
        >
          {(fieldsData, { add, remove }, { errors }) => {
            return (
              <>
                <table>
                  <thead>
                    <th style={{ width: "180px", paddingBottom: "10px" }}>
                      Field Name
                    </th>
                    <th style={{ width: "180px", paddingBottom: "10px" }}>
                      Field Label
                    </th>
                    <th style={{ width: "180px", paddingBottom: "10px" }}>
                      HTML Type
                    </th>
                    <th style={{ width: "180px", paddingBottom: "10px" }}>
                      Default Value
                    </th>
                    <th style={{ width: "100px", paddingBottom: "10px" }}>
                      Required
                    </th>
                    <th style={{ width: "100px", paddingBottom: "10px" }}>
                      Delete
                    </th>
                  </thead>
                  {fieldsData?.map((field, index) => (
                    <>
                      <tbody>
                        <td style={{ width: "170px", margin: "0px 10px" }}>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            name={[index, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter name!",
                              },
                              {
                                validator: (_, value) =>
                                  !value.includes(" ")
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error("No spaces allowed")
                                      ),
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "150px" }}
                              disabled={
                                editCategoryTypeData
                                  ? editCategoryTypeData.fields.length > index
                                    ? true
                                    : false
                                  : false
                              }
                              placeholder="Name"
                              defaultValue={
                                editCategoryTypeData
                                  ? editCategoryTypeData.fields.length > index
                                    ? editCategoryTypeData.fields[index].name
                                    : ""
                                  : ""
                              }
                            />
                          </Form.Item>
                        </td>

                        <td style={{ width: "170px", margin: "0px 10px" }}>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            name={[index, "label"]}
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                            initialValue={
                              editCategoryTypeData
                                ? editCategoryTypeData.fields.length > index
                                  ? editCategoryTypeData.fields[index].label
                                  : ""
                                : ""
                            }
                          >
                            <Input
                              style={{ width: "150px" }}
                              placeholder="Please enter label"
                              disabled={
                                editCategoryTypeData
                                  ? editCategoryTypeData.fields.length > index
                                    ? true
                                    : false
                                  : false
                              }
                              defaultValue={
                                editCategoryTypeData &&
                                editCategoryTypeData.fields.length > index
                                  ? editCategoryTypeData.fields[index].label
                                  : ""
                              }
                            />
                          </Form.Item>
                        </td>

                        <td style={{ width: "170px", margin: "0px 10px" }}>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            name={[index, "htmlType"]}
                            // label="HTML Type"
                            rules={[
                              {
                                required: true,
                                message: "Please select html type",
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "150px" }}
                              // className="ios-type-color"
                              allowClear
                              disabled={
                                editCategoryTypeData
                                  ? editCategoryTypeData.fields.length > index
                                    ? true
                                    : false
                                  : false
                              }
                              onChange={(e) => {
                                onHtmlTypeChange(e, "htmlType", index);
                              }}
                              placeholder="Select html type"
                              options={HTML_TYPE}
                            />
                          </Form.Item>
                        </td>

                        <td style={{ width: "170px", margin: "0px 10px" }}>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            name={[index, "defaultValue"]}
                            rules={[
                              {
                                required: false,
                                message: "Please input your defaultValue!",
                              },
                            ]}
                          >
                            {defaultFieldData &&
                              defaultFieldData.fields.length > index &&
                              defaultFieldData.fields[index].htmlType ===
                                "text" && (
                                <Input
                                  style={{ width: "150px" }}
                                  placeholder="DefaultValue"
                                  disabled={
                                    editCategoryTypeData
                                      ? editCategoryTypeData.fields.length >
                                        index
                                        ? true
                                        : false
                                      : false
                                  }
                                  defaultValue={
                                    editCategoryTypeData
                                      ? editCategoryTypeData.fields.length >
                                        index
                                        ? editCategoryTypeData.fields[index]
                                            .defaultValue
                                        : ""
                                      : ""
                                  }
                                />
                              )}
                            {defaultFieldData &&
                              defaultFieldData.fields.length > index &&
                              defaultFieldData.fields[index].htmlType ===
                                "boolean" && (
                                <Select
                                  style={{ width: "150px" }}
                                  // className="ios-type-color"
                                  allowClear
                                  disabled={
                                    editCategoryTypeData
                                      ? editCategoryTypeData.fields.length >
                                        index
                                        ? true
                                        : false
                                      : false
                                  }
                                  placeholder="Select html type"
                                  options={HTML_TYPE_DEFAULT_VALUE}
                                />
                              )}

                            {(!defaultFieldData ||
                              defaultFieldData.fields.length) < 1 && (
                              <Input
                                style={{ width: "150px" }}
                                placeholder="DefaultValue"
                                disabled={
                                  editCategoryTypeData
                                    ? editCategoryTypeData.fields.length > index
                                      ? true
                                      : false
                                    : false
                                }
                                defaultValue={
                                  editCategoryTypeData
                                    ? editCategoryTypeData.fields.length > index
                                      ? editCategoryTypeData.fields[index]
                                          .defaultValue
                                      : ""
                                    : ""
                                }
                              />
                            )}
                          </Form.Item>
                        </td>

                        <td
                          style={{
                            width: "100px",
                            margin: "0px 10px",
                          }}
                        >
                          <Form.Item hidden>
                            <Input />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            name={[index, "isRequired"]}
                            valuePropNameIsReq="checked"
                            rules={[
                              {
                                required: false,
                                message: "Please select",
                              },
                            ]}
                          >
                            {editCategoryTypeData && (
                              <Switch
                                style={{ width: "50px" }}
                                disabled={
                                  editCategoryTypeData
                                    ? editCategoryTypeData.fields.length > index
                                      ? true
                                      : false
                                    : false
                                }
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                defaultChecked={
                                  editCategoryTypeData
                                    ? editCategoryTypeData.fields.length > index
                                      ? editCategoryTypeData.fields[index]
                                          .isRequired
                                      : "Yes"
                                    : "Yes"
                                }
                              />
                            )}

                            {!editCategoryTypeData && (
                              <Switch
                                style={{ width: "50px" }}
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                defaultChecked="No"
                              />
                            )}
                          </Form.Item>
                        </td>

                        <td
                          style={{
                            width: "100px",
                            margin: "0px 10px",
                            textAlign: "center",
                            verticalAlign: "baseline",
                          }}
                        >
                          {fieldsData.length > 0 ? (
                            <Button
                              type="primary"
                              danger
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(field.name);
                                let data = defaultFieldData.fields;
                                if (index >= 0 && index < data.length) {
                                  defaultFieldData.fields.splice(index, 1); // Remove 1 item at the specified index
                                  setDefaultFieldData({
                                    ...defaultFieldData,
                                    fields: defaultFieldData.fields,
                                  });
                                } else {
                                }
                              }}
                              icon={<CloseOutlined />}
                            ></Button>
                          ) : null}
                        </td>
                      </tbody>
                    </>
                  ))}
                  <Divider className="divider_temp" />
                  <div>
                    <Form.Item className="add_temp_layer_btn">
                      <Button
                        type="dashed"
                        onClick={() => add(fieldsData.name)}
                      >
                        <PlusOutlined /> Add Field
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </div>
                </table>
              </>
            );
          }}
        </Form.List>
      </>
    );
  };

  return (
    <>
      <p className="add_temp_btn">
        {" "}
        {editCategoryTypeData ? "Edit" : "Add"} Category Type
      </p>
      <Divider></Divider>

      <div className="add_cate_form">
        <Form
          form={addCategoryTypeForm}
          className="common-form"
          name="add-product"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={defaultData}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div style={{ width: "50%" }}>
            <Form.Item hidden={true} name="_id">
              <Input />
            </Form.Item>

            {editCategoryTypeData && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  marginBottom: "10px",
                  flex: "1 1 0",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "14px", fontWeight: "400" }}>Name : </p>{" "}
                <div
                  style={{
                    // border: "1px solid lightgray ",
                    width: "67%",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <p style={{ fontSize: "14px", fontWeight: "400" }}>
                    {editCategoryTypeData.name}
                  </p>
                </div>
              </div>
            )}
            <Form.Item
              label="Name"
              name="name"
              hidden={editCategoryTypeData}
              rules={[
                {
                  required: true,
                  message: "Please enter your name!",
                },
              ]}
            >
              <Input placeholder="Name" className="add_form" />
            </Form.Item>

            <Form.Item
              label="Active Status"
              name="activeStatus"
              valuePropActiveType="checkedActive"
              rules={[
                {
                  required: false,
                  message: "Please select Active Status",
                },
              ]}
            >
              {editCategoryTypeData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={editCategoryTypeData.activeStatus}
                />
              )}
              {!editCategoryTypeData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked="Yes"
                />
              )}
            </Form.Item>
          </div>

          <div>
            {editCategoryTypeData &&
              // editCategoryTypeData._id !== "656705c2c09b55f8ccc92fc3" &&
              // editCategoryTypeData._id !== "656705c2c09b55f8ccc92fc5" &&

              editCategoryTypeData.name !== "sticker" &&
              editCategoryTypeData.name !== "template" &&
              fields()}
            {!editCategoryTypeData && fields()}
          </div>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button
                type="default"
                icon={<ArrowLeftOutlined />}
                className="ios-type-color"
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button
                className="ios-type-color"
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      <div className="add_side_img"></div>
    </>
  );
};
export default AddCategoryType;
