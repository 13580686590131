import React, { useEffect, useRef, useState } from 'react';
import { setProductDetails } from '../../Store/Reducers/Slices/Product/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddTemplate from '../../Components/Template/addTemplate';
import { httpClient, isSuccessStatus } from '../../Api/client';
import Urls from '../../Constant/Urls';
import Loader from '../../Components/Common/Loader';
import { isNullOrEmpty, objectToQueryString } from '../../Util/commonUtility';
import { DefaultPagingValue } from '../../Constant/Common';
import TemplateListing from '../../Components/Template/TemplateListing';
import { useLocation } from 'react-router-dom';
import AddSticker from '../../Components/Sticker/addSticker';
import { message } from 'antd';
import AddGeneric from '../../Components/Template/addGeneric';
import MessageNotification from '../../Components/Common/messageNotification';

const TemplateContainer = (route, navigation) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showLoading, SetShowLoading] = useState(false);
  const [isAddTemplate, SetIsAddTemplate] = useState(false);
  const [isAddSticker, SetIsAddSticker] = useState(false);
  const [isAddGeneric, SetIsAddGeneric] = useState(false);
  const [templates, SetTemplates] = useState([]);
  const [stickers, SetStickers] = useState([]);
  const [editTemplateData, SetEditTemplateData] = useState();
  const [allCategories, SetAllCategories] = useState([]);
  const [generic, SetGeneric] = useState([]);
  const [editGenericData, SetEditGenericData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [catType, SetCatType] = useState();
  const [totalRecords, SetTotalRecords] = useState('');

  const [filterData, setFilterData] = useState(undefined);
  const [searchData, setSearchData] = useState();
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortTitle, setSortTitle] = useState('Click to Sort Descending');
  const searchInput = useRef(null);

  const [paging, SetPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  useEffect(() => {
    if (location.state.categoryData.type === 'template') {
      fetchAllTemplates(location.state.categoryData);
      fetchAllCategories();
    } else if (location.state.categoryData.type === 'sticker') {
      fetchAllStickers(location.state.categoryData);
      fetchAllCategories();
    } else {
      fetchAllGeneric(location.state.categoryData);
      fetchAllCategories();
    }
    getItemTypeID();
  }, [paging.pageIndex, paging.pageSize, filterData]);

  const getItemTypeID = () => {
    location.state.catTypeData.forEach((obj, index) => {
      if (obj.label === location.state.categoryData.type) {
        SetCatType(obj.value);
      }
    });
  };

  const handleAddItem = () => {
    if (location.state.categoryData.type === 'sticker') {
      SetIsAddSticker(true);
    } else if (location.state.categoryData.type === 'template') {
      SetIsAddTemplate(true);
    } else {
      SetIsAddGeneric(true);
    }
  };

  const handleEditItem = (data) => {
    if (location.state.categoryData.type === 'sticker') {
      editSticker(data);
    } else if (location.state.categoryData.type === 'template') {
      editTemplate(data);
    } else {
      editGeneric(data);
    }
  };

  const handleDeleteItem = (data) => {
    if (location.state.categoryData.type === 'sticker') {
      deleteSticker(data);
    } else if (location.state.categoryData.type === 'template') {
      deleteTemplate(data);
    } else {
      deleteGeneric(data);
    }
  };

  const submitAddTemplateForm = async (data) => {
    console.log(data);
    SetShowLoading(true);
    if (data._id) {
      let newLayers = [];
      data.layers.forEach((layer, index) => {
        let dataObj = {};
        layer?.data_object?.forEach((item) => {
          dataObj[item.key] = item.value;
        });

        let dataToPass = {
          sequence: index,
          friendlyName: layer.friendlyName,
          imageUrl: layer.imageUrl,
          data_object: dataObj,
          type: 'Image',
        };
        newLayers.push(dataToPass);
      });
      let editTempData = {
        height: data.height,
        width: data.width,
        isPremium: data.isPremium !== undefined ? data.isPremium : false, //data.isPremium,
        activeStatus:
          data.activeStatus !== undefined ? data.activeStatus : true,
        osType: location.state.categoryData.osType,
        template: {
          name: data.name,
          thumbnailImage: data.thumbnailImage,
          sequence: data.sequence,
          layers: newLayers,
        },
      };
      const response = await httpClient.put(
        Urls.TEMPLATE_UPDATE_TEMPLATE_BY_ID.replace('#{templateID}', data._id),
        editTempData
      );
      if (isSuccessStatus(response?.status)) {
        MessageNotification('success', response.data.message);

        resetEditFormState();
        fetchAllTemplates(location.state.categoryData);
      } else {
      }
    } else {
      let newLayers = [];
      data.layers.forEach((layer, index) => {
        let dataObj = {};
        layer?.data_object?.forEach((item) => {
          dataObj[item.key] = item.value;
        });

        let dataToPass = {
          sequence: index,
          friendlyName: layer.friendlyName,
          imageUrl: layer.imageUrl,
          data_object: dataObj,
          type: 'Image',
        };
        newLayers.push(dataToPass);
      });
      let addTempData = {
        height: data.height,
        width: data.width,
        osType: location.state.categoryData.osType,
        type: catType,
        isPremium: data.isPremium !== undefined ? data.isPremium : false,
        activeStatus: true,
        template: {
          thumbnailImage: data.thumbnailImage,
          categoryId: location.state.categoryData._id,
          name: data.name,
          sequence: data.sequence === undefined ? 1 : data.sequence,
          layers: newLayers,
        },
      };
      const response = await httpClient.post(
        Urls.TEMPLATE_ADD_TEMPLATE,
        addTempData
      );
      if (isSuccessStatus(response?.status)) {
        MessageNotification('success', response.data.message);

        resetEditFormState();
        fetchAllTemplates(location.state.categoryData);
      } else {
      }
    }
    SetIsAddTemplate(false);
    SetShowLoading(false);
  };

  const submitAddStickerForm = async (data) => {
    SetShowLoading(true);
    if (data._id) {
      let editStickerData = {
        isPremium: data.isPremium,
        activeStatus:
          data.activeStatus !== undefined ? data.activeStatus : true,
        osType: location.state.categoryData.osType,
        sticker: {
          name: data.name,
          thumbnailImage: data.thumbnailImage,
          sequence: data.sequence,
        },
      };
      const response = await httpClient.put(
        Urls.STICKER_UPDATE_STICKER_BY_ID.replace('#{stickerID}', data._id),
        editStickerData
      );
      if (isSuccessStatus(response?.status)) {
        resetEditFormState();
        MessageNotification('success', response.data.message);

        resetEditFormState();
        fetchAllStickers(location.state.categoryData);
      } else {
      }
    } else {
      let addStickerData = {
        osType: location.state.categoryData.osType,
        type: catType, //location.state.categoryData.type,
        isPremium: data.isPremium,
        activeStatus: true,
        sticker: {
          thumbnailImage: data.thumbnailImage,
          categoryId: location.state.categoryData._id,
          name: data.name,
          sequence: data.sequence === undefined ? 1 : data.sequence,
        },
      };
      const response = await httpClient.post(
        Urls.STICKER_ADD_STICKER,
        addStickerData
      );
      if (isSuccessStatus(response?.status)) {
        resetEditFormState();
        MessageNotification('success', response.data.message);

        fetchAllStickers(location.state.categoryData);
      } else {
      }
    }
    SetIsAddSticker(false);
    SetShowLoading(false);
  };

  const submitAddGenericForm = async (data) => {
    SetShowLoading(true);
    if (data._id) {
      let genericObject = {
        categoryId: location.state.categoryData._id,
        sequence: data.sequence,
        name: data.name,
        dataObject: data.fields,
      };

      let addGenericData = {
        osType: location.state.categoryData.osType,
        isPremium: data.isPremium,
        activeStatus: data.activeStatus,
        type: getFormID(),
        genericObject: genericObject,
      };
      const response = await httpClient.put(
        Urls.GENERIC_UPDATE_GENERIC_BY_ID.replace('#{ID}', data._id),
        addGenericData
      );
      if (isSuccessStatus(response?.status)) {
        resetEditFormState();
        MessageNotification('success', response.data.message);

        resetEditFormState();
        fetchAllGeneric(location.state.categoryData);
      } else {
      }
    } else {
      let newData = data;
      if (data.sequence === (null || undefined)) {
        newData.sequence = 1;
      }
      if (data.activeStatus === (undefined || null)) {
        newData.activeStatus = true;
      }

      let genericObject = {
        categoryId: location.state.categoryData._id,
        sequence: newData.sequence,
        name: newData.name,
        dataObject: newData.fields,
      };

      let addGenericData = {
        osType: location.state.categoryData.osType,
        isPremium: data.isPremium !== undefined ? data.isPremium : false,
        activeStatus: newData.activeStatus,
        type: getFormID(),
        genericObject: genericObject,
      };

      const response = await httpClient.post(
        Urls.GENERIC_ADD_GENERIC,
        addGenericData
      );
      if (isSuccessStatus(response?.status)) {
        resetEditFormState();
        MessageNotification('success', response.data.message);

        fetchAllGeneric(location.state.categoryData);
      } else {
      }
    }
    SetIsAddGeneric(false);
    SetShowLoading(false);
  };

  const getFormID = () => {
    return location.state.catTypeData.filter(
      (obj) => obj.label === location.state.categoryData.type
    )[0].value;
  };

  const fetchAllTemplates = async (data) => {
    SetShowLoading(true);
    let filterDatas = '';
    if (filterData) {
      filterDatas = objectToQueryString(filterData);
    }
    const response = await httpClient.get(
      `${Urls.TEMPLATE_GET_TEMPLATE_BY_CATEGORYID.replace(
        '#{categoryId}',
        data._id
      )}?page=${paging.pageIndex}
      &limit=${paging.pageSize}&${filterDatas && filterDatas}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetTemplates(response?.data.data);
      if (response?.data.data) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_SIZE,
        });
      }
    } else {
    }
    SetShowLoading(false);
  };

  const fetchAllStickers = async (data) => {
    SetShowLoading(true);
    let filterDatas = '';
    if (filterData) {
      filterDatas = objectToQueryString(filterData);
    }

    const response = await httpClient.get(
      `${Urls.STICKER_GET_STICKER_BY_CATEGORYID.replace(
        '#{categoryId}',
        data._id
      )}?page=${paging.pageIndex}
      &limit=${paging.pageSize}&${filterDatas && filterDatas}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      SetStickers(response?.data.data);
      if (response?.data.data) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_SIZE,
        });
      }
    } else {
    }
    SetShowLoading(false);
  };

  const fetchAllGeneric = async (data) => {
    SetShowLoading(true);
    let filterDatas = '';
    if (filterData) {
      filterDatas = objectToQueryString(filterData);
    }

    const response = await httpClient.get(
      `${Urls.GENERIC_GET_GENERIC_BY_CATEGORYID.replace(
        '#{Id}',
        data._id
      )}?page=${paging.pageIndex}
      &limit=${paging.pageSize}&${filterDatas && filterDatas}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      // SetGeneric(response?.data.data);
      SetGeneric(response?.data.data.result);
      SetTotalRecords(response?.data.data.totalCount);
      if (response?.data.data) {
        SetPaging({
          ...paging,
          pageIndex: response?.data?.data?.currentPage,
          totalPages: response?.data?.data?.totalPage,
        });
      }
      if (response.data?.result?.length <= 0) {
        SetPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_SIZE,
        });
      }
    } else {
    }
    SetShowLoading(false);
  };

  const fetchAllCategories = async () => {
    let filterDatas = '';
    if (filterData) {
      filterDatas = objectToQueryString(filterData);
    }
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.CATEGORY_GET_ALL_CATEGORIES}?page=${paging.pageIndex}&limit=${
        paging.pageSize
      }&${filterDatas && filterDatas}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      let customCats = [];
      response?.data.data.categories.map((cat) => {
        let newCatObj = {
          label: cat.name,
          value: cat._id,
          type: cat.osType[0],
        };
        customCats.push(newCatObj);
      });
      SetAllCategories(customCats);
    } else {
    }
    SetShowLoading(false);
  };
  const deleteTemplate = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.TEMPLATE_DELETE_BY_ID.replace('#{templateID}', data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification('success', response.data.message);

      fetchAllTemplates(location.state.categoryData);
    } else {
    }
    SetShowLoading(false);
  };

  const deleteGeneric = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.GENERIC_DELETE_BY_ID.replace('#{ID}', data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification('success', response.data.message);

      fetchAllGeneric(location.state.categoryData);
    } else {
    }
    SetShowLoading(false);
  };

  const deleteSticker = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.delete(
      `${Urls.STICKER_DELETE_BY_ID.replace('#{stickerID}', data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      MessageNotification('success', response.data.message);

      fetchAllStickers(location.state.categoryData);
    } else {
    }
    SetShowLoading(false);
  };

  const editTemplate = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.TEMPLATE_GET_TEMPLATE_BY_ID.replace('#{templateID}', data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      SetIsAddTemplate(true);
      let data = response?.data?.data;
      console.log(data);
      data?.layers?.forEach((layer) => {
        layer.data_object = Object.entries(layer.data_object).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
      });
      SetEditTemplateData(data);
    } else {
    }
    SetShowLoading(false);
  };

  console.log(editTemplateData);

  const editSticker = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.STICKER_GET_STICKER_BY_ID.replace('#{stickerID}', data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      SetIsAddSticker(true);
      SetEditTemplateData(response?.data?.data);
    } else {
    }
    SetShowLoading(false);
  };

  const editGeneric = async (data) => {
    SetShowLoading(true);
    const response = await httpClient.get(
      `${Urls.GENERIC_GET_GENERICE_BY_ID.replace('#{ID}', data._id)}`
    );
    if (isSuccessStatus(response?.status)) {
      SetEditGenericData(response?.data?.data);
      SetIsAddGeneric(true);
    } else {
    }
    SetShowLoading(false);
  };

  const handlePageChange = async (page, pageSize) => {
    SetPaging({
      ...paging,
      pageIndex: page,
      pageSize: pageSize,
    });
  };
  const resetEditFormState = () => {
    SetIsAddTemplate(false);
    SetEditTemplateData(null);
    SetIsAddSticker(false);
    SetIsAddGeneric(false);
    SetEditGenericData(null);
  };

  const onFilterFinish = (data) => {
    setFilterData(data);
  };

  const toggleSortOrder = (order) => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const handleFilter = (value, record) => {
    setSortField(value);
    let data = {
      searchText: '',
      sortField: sortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };
  const handleSort = (column, sortOrderNew) => {
    let newSortField = column;
    if (column === 'name' || column === 'sequence') {
      if (location.state.categoryData.type === 'template') {
        newSortField = 'template' + '.' + newSortField;
      } else if (location.state.categoryData.type === 'sticker') {
        newSortField = 'sticker' + '.' + newSortField;
      }
    } else if (column instanceof Array && column.length > 1) {
      newSortField = column[0] + '.' + column[1];
    } else {
      newSortField = column;
    }

    if (sortOrderNew === 'desc') {
      setSortTitle('Click to Sort Ascending');
    } else {
      setSortTitle('Click to Sort Descending');
    }
    toggleSortOrder(sortOrder);
    setSortField(column);

    let data = {
      searchText: '',
      sortField: newSortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };

  const handleSearch = (searchText) => {
    setSearchData(searchText);

    let data = {
      searchText: searchText,
      sortField: sortField,
      order: sortOrder,
    };
    onFilterFinish(data);
  };

  const handleReset = () => {
    setFilterData(undefined);
    setSearchData(undefined);
  };

  return (
    <>
      {contextHolder}
      {showLoading ? (
        <Loader />
      ) : isAddTemplate ? (
        <AddTemplate
          onFinish={(data) => {
            submitAddTemplateForm(data);
          }}
          categoriesDropdownValues={allCategories}
          editTemplateData={editTemplateData}
          selectedCatData={location.state.categoryData}
          backToListPage={() => resetEditFormState()}
        />
      ) : isAddSticker ? (
        <AddSticker
          onFinish={(data) => {
            submitAddStickerForm(data);
          }}
          categoriesDropdownValues={allCategories}
          editTemplateData={editTemplateData}
          selectedCatData={location.state.categoryData}
          backToListPage={() => resetEditFormState()}
        />
      ) : isAddGeneric ? (
        <AddGeneric
          onFinish={(data) => {
            submitAddGenericForm(data);
          }}
          type={location.state.categoryData.type}
          catTypeid={location.state.categoryData._id}
          allCategoryTypes={location.state.catTypeData}
          editGenericData={editGenericData}
          selectedCatData={location.state.categoryData}
          backToListPage={() => resetEditFormState()}
        />
      ) : (
        <TemplateListing
          dataSource={
            location.state.categoryData.type === 'template'
              ? templates
              : location.state.categoryData.type === 'sticker'
              ? stickers
              : generic
          }
          catTypeData={location.state.catTypeData}
          categoryType={location.state.categoryData.type}
          addGeneric={() => handleAddItem()}
          editGeneric={(data) => handleEditItem(data)}
          deleteGeneric={(data) => handleDeleteItem(data)}
          // editTemplate={(data) =>
          //   location.state.categoryData.type === "template"
          //     ? editTemplate(data)
          //     : editSticker(data)
          // }
          // deleteTemplate={(data) =>
          //   location.state.categoryData.type === "template"
          //     ? deleteTemplate(data)
          //     : deleteSticker(data)
          // }
          totalRecords={totalRecords}
          paging={paging}
          handlePageChange={(page, pageSize) => {
            handlePageChange(page, pageSize);
          }}
          filterData={filterData}
          sortOrder={sortOrder}
          sortField={sortField}
          sortTitle={sortTitle}
          handleSort={(column, sortOrderNew) =>
            handleSort(column, sortOrderNew)
          }
          handleFilter={(value, record) => handleFilter(value, record)}
          searchedText={searchData}
          handleSearch={(searchText) => handleSearch(searchText)}
          handleReset={(clearFilters) => handleReset(clearFilters)}
          searchInput={searchInput}
        />
      )}
    </>
  );
};

export default TemplateContainer;
