import BreadCrumb from "../Common/breadCrumb";
import { breadCrumbsPathForPages } from "../../Util/commonUtility";
import { Pie } from "@ant-design/plots";
import { Bar } from "@ant-design/plots";
import { useEffect } from "react";

const DashboardListing = (props) => {
  const { dataSource } = props;
  const localAccount = localStorage.getItem("account");

  let Cap = () => {
    return <></>;
  };

  const DemoBar = (dataSourceObj, type) => {
    console.log("Check", dataSourceObj, type);
    const data = [
      {
        country:
          type === "category"
            ? "Category"
            : type === "sticker"
            ? "Sticker"
            : type === "admin"
            ? "Admin"
            : type === "template"
            ? "Template"
            : type,
        year:
          type === "category"
            ? "Total Category"
            : type === "sticker"
            ? "Total Sticker"
            : type === "admin"
            ? "Total Admin"
            : type === "template"
            ? "Total Template"
            : "Total " + type,
        value: dataSourceObj?.total,
      },

      {
        country:
          type === "category"
            ? "Category"
            : type === "sticker"
            ? "Sticker"
            : type === "admin"
            ? "Admin"
            : type === "template"
            ? "Template"
            : type,
        year:
          type === "category"
            ? "Active Category"
            : type === "sticker"
            ? "Active Sticker"
            : type === "admin"
            ? "Active Admin"
            : type === "template"
            ? "Active Template"
            : "Active " + type,
        value: dataSourceObj?.active,
      },
      {
        country:
          type === "category"
            ? "Category"
            : type === "sticker"
            ? "Sticker"
            : type === "admin"
            ? "Admin"
            : type === "template"
            ? "Template"
            : type,
        year:
          type === "category"
            ? "Deleted Category"
            : type === "sticker"
            ? "Deleted Sticker"
            : type === "admin"
            ? "Active Admin"
            : type === "template"
            ? "Deleted Template"
            : "Deleted " + type,
        value: dataSourceObj?.deleted,
      },
    ];
    const config = {
      data,
      xField: "value",
      yField: "country",
      seriesField: "year",
      isPercent: false,
      xAxis: {
        line: { style: { lineWidth: 0 } },
        tickLine: { style: { lineWidth: 0 } },
      },

      // isStack: false,
      // inGroup: true,
      color: ["#2582a1", "#f88c24", "#c52125", "#87f4d0"],
      // line: { stroke: "#e8e8e8", lineWidth: 0 },
      // label: {
      //   // position: "middle",
      //   // content: (item) => {
      //   //   return item.value.toFixed(0);
      //   // },
      //   style: {
      //     fill: "#fff",
      //   },
      // },
    };
    return <Bar {...config} />;
  };

  const DemoPie = (dataSourceObj, type) => {
    const data = [
      {
        type:
          type === "category"
            ? "iOS Total Category"
            : type === "admin"
            ? "Total SuperAdmins"
            : type === "sticker"
            ? "iOS Total Sticker"
            : type === "template"
            ? "iOS Total Template"
            : "iOS Total " + type,
        value: dataSourceObj?.totalIos,
      },
      {
        type:
          type === "category"
            ? "iOS Active Category"
            : type === "admin"
            ? "Total Active SuperAdmins"
            : type === "sticker"
            ? "iOS Active Sticker"
            : type === "template"
            ? "iOS Active Template"
            : "iOS Active " + type,
        value: dataSourceObj?.activeIos,
      },
      {
        type:
          type === "category"
            ? "Android Total Category"
            : type === "sticker"
            ? "Android Total Sticker"
            : type === "template"
            ? "Android Total Template"
            : "Android Total " + type,
        value: dataSourceObj?.totalAndroid,
      },
      {
        type:
          type === "category"
            ? "Android Active Category"
            : type === "sticker"
            ? "Android Active Sticker"
            : type === "template"
            ? "Android Active Template"
            : "Android Active " + type,
        value: dataSourceObj?.activeAndroid,
      },
    ];

    const config = {
      appendPadding: 10,
      data,
      theme: "white",
      angleField: "value",
      colorField: "type",
      radius: 0.8,
      innerRadius: 0,
      // color: ["#c52125", "#2582a1", "#f88c24", "#87f4d0"],
      label: {
        type: "inner",
        offset: "-50%",
        autoRotate: true,

        style: {
          textAlign: "center",
          fill: "#fff",
        },
      },
      statistic: {
        title: {
          offsetY: -8,
          style: {
            color: "black",
          },
        },
        content: {
          style: {
            color: "black",
          },
          offsetY: -4,
        },
      },
      pieStyle: {
        lineWidth: 0,
      },
    };
    return <Pie {...config} />;
  };
  return (
    <div>
      <div className="bread-Crumb-Container" style={{ marginBottom: "10px" }}>
        <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.dashboard} />
      </div>

      <div
        style={{
          display: "flex",
          // height: "80vh",
          // alignContent: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            padding: "20px",
            margin: "20px",
            width: "30%",
          }}
        >
          <div>CATEGORY</div>
          <div style={{ width: "100%", height: "140px", padding: "20px" }}>
            {DemoBar(dataSource.CategoryDetails, "category")}
          </div>
          <div
            style={{
              width: "100%",
              height: "240px",
            }}
          >
            {DemoPie(dataSource.CategoryDetails, "category")}
          </div>
        </div>
        {dataSource.TypesDetails?.map((data, index) => {
          console.log(index, data.data);
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid black",
                padding: "20px",
                margin: "20px",
                // width: "46%",
                width: "30%",
              }}
            >
              <div>{data.type.toUpperCase(0)}</div>
              <div style={{ width: "100%", height: "140px", padding: "20px" }}>
                {DemoBar(data.Data, data.type)}
              </div>
              <div
                style={{
                  width: "100%",
                  height: "240px",
                }}
              >
                {DemoPie(data.Data, data.type)}
              </div>
            </div>
          );
        })}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            padding: "20px",
            margin: "20px",
            width: "30%",
          }}
        >
          <div style={{ width: "100%", height: "150px", padding: "20px" }}>
            {DemoBar(
              dataSource.TypesDetails.filter(
                (obj) => obj.type === "template"
              )[0],
              "template"
            )}
          </div>
          <div
          // style={{
          //   margin: "20px",
          // }}
          >
            {DemoPie(
              dataSource.TypesDetails.filter(
                (obj) => obj.type === "template"
              )[0],
              "template"
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            padding: "20px",
            margin: "20px",
            width: "30%",
          }}
        >
          <div style={{ width: "100%", height: "150px", padding: "20px" }}>
            {DemoBar(
              dataSource.TypesDetails.filter(
                (obj) => obj.type === "sticker"
              )[0],
              "sticker"
            )}
          </div>
          <div
          // style={{
          //   margin: "20px",
          // }}
          >
            {DemoPie(
              dataSource.TypesDetails.filter(
                (obj) => obj.type === "sticker"
              )[0],
              "sticker"
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            padding: "20px",
            margin: "20px",
            width: "30%",
          }}
        >
          <div style={{ width: "100%", height: "150px", padding: "20px" }}>
            {DemoBar(
              dataSource.TypesDetails.filter((obj) => obj.type === "banner")[0],
              "banner"
            )}
          </div>
          <div
          // style={{
          //   margin: "20px",
          // }}
          >
            {DemoPie(
              dataSource.TypesDetails.filter((obj) => obj.type === "banner")[0],
              "banner"
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            padding: "20px",
            margin: "20px",
            width: "30%",
          }}
        >
          <div style={{ width: "100%", height: "150px", padding: "20px" }}>
            {DemoBar(
              dataSource.TypesDetails.filter((obj) => obj.type === "video")[0],
              "video"
            )}
          </div>
          <div
          // style={{
          //   margin: "20px",
          // }}
          >
            {DemoPie(
              dataSource.TypesDetails.filter((obj) => obj.type === "video")[0],
              "video"
            )}
          </div>
        </div> */}

        {/* {localAccount.includes("superadmin") && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid black",
              padding: "20px",
              margin: "20px",
              width: "30%",
            }}
          >
            <div style={{ width: "100%", height: "150px", padding: "20px" }}>
              {DemoBar(dataSource.admin, "admin")}
            </div>
            <div
            // style={{
            //   margin: "20px",
            // }}
            >
              {DemoPie(dataSource.admin, "admin")}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default DashboardListing;
