import { message, notification } from 'antd'

import React from 'react'

const MessageNotification = (type, description) => {
    message[type]({
       content:description
      });
}

export default MessageNotification