import { Button, Form, Input, Select, Switch, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../Common/breadCrumb";
import {
  breadCrumbsPathForPages,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import "./addEditSequence.scss";
import LocationJson from "../../Constant/ISO3.json";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import TableTransferComponent from "./TableTransferComponent";
import { HttpStatusCode } from "axios";
import { HTTP_OK } from "../../Constant/HttpStatus";
import { useForm } from "antd/es/form/Form";
import NotificationService from "../../Services/notification";
import {
  CATEGORY_TYPES_ENUM,
  DEFAULT_SEQUENCE_NAME,
  DefaultPagingValue,
} from "../../Constant/Common";

const { Option } = Select;

const LocationArray = LocationJson.map((loc) => {
  return <Option value={loc.code}>{`${loc.code} - ${loc.name}`}</Option>;
});

const locationArrayLength = LocationJson.length;

const AddOrEditCategorySequence = ({ isEdit }) => {
  const location = useLocation();
  const [isSearch, setIsSearch] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [categories, setCategories] = useState([]);
  const [currentSequenceDetails, setCurrentSequenceDetails] = useState({});
  const [categoryTypes, setCategoriesTypes] = useState([]);
  const [paging, setPaging] = useState({
    page: DefaultPagingValue.PAGE_INDEX,
    size: DefaultPagingValue.PAGE_SIZE,
  });
  const [form] = useForm();
  const navigate = useNavigate();
  const { id: categorySequenceId } = useParams();

  const rightColumns = [
    {
      dataIndex: "name",
      title: "Name",
      sort: true,
    },
    {
      title: "Sequence",
      key: "sequence",
      dataIndex: "sequence",
      editable: true,
      render: (_text, _data, index) => {
        return isSearch
          ? targetKeys.findIndex((key) => key === _data.key) + 1
          : index + 1;
        //return index + 1 + (paging.size * paging.page - paging.size);
      },
    },
  ];

  const columns = rightColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const leftColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => record.name,
    },
    {
      title: "Active Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) =>
        String(record.activeStatus).indexOf(String(value)) === 0,
      render: (text) => (
        <Tag
          className="common-tag-color"
          color={text === true ? "green" : "red"}
        >
          {String(text ? "Yes" : "No")}
        </Tag>
      ),
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
    },
    {
      title: "Category Type",
      dataIndex: "type",
      key: "type",
      // filters: categoryTypes.map((type) => {
      //   return { text: type.name, value: type.name };
      // }),
      // onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: "OsType",
      dataIndex: "osType",
      key: "osType",
      filters: [
        {
          text: "android",
          value: "android",
        },
        {
          text: "ios",
          value: "ios",
        },
      ],
      onFilter: (value, record) => record.osType.includes(value),
      render: (osType) => {
        return (
          <>
            {osType?.map((osType, index) => {
              return (
                <Tag key={index} className="common-tag-color" color="blue">
                  {" "}
                  {osType}{" "}
                </Tag>
              );
            })}
          </>
        );
      },
    },
  ];

  const handleSave = (row) => {
    const newData = [...categories];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setCategories(newData);
  };

  const handleSearch = (direction, value) => {
    setIsSearch(direction === "right" && value);
  };

  const filterOption = (input, item) => {
    return item.name.toLowerCase()?.includes(input.toLowerCase());
  };

  const getCategories = async () => {
    const response = await httpClient.get(
      `${Urls.CATEGORY_GET_ALL_CATEGORIES}?limit=1000`
    );
    if (response.status === 200) {
      const allCategories = response?.data?.data?.categories
        .filter((category) => category.type === CATEGORY_TYPES_ENUM.template)
        .map((category, i) => ({
          ...category,
          key: i.toString(),
        }));
      if (categorySequenceId) {
        await getCategorySequenceFromId(allCategories);
        return;
      }
      setCategories(allCategories);
    }
  };

  const getCategorySequenceFromId = async (categoriesRes) => {
    const response = await httpClient.get(
      Urls.GET_CATEGORY_SEQUENCE.replace("#{sequenceId}", categorySequenceId)
    );

    if (response.status === 200) {
      form.setFieldsValue(response.data.data);
      setCurrentSequenceDetails(response.data.data);
      setIsEnabled(response?.data?.data?.isEnabled || false);

      if (response.data.data.location.includes("ALL")) {
        const allLocations = LocationJson.map((loc) => loc.code);
        form.setFieldsValue({ location: ["ALL", ...allLocations] });
      }

      let indexArray = [];

      const finalCategories = categoriesRes.map((category, index) => {
        const isExist =
          response?.data?.data?.categorySequences.find(
            (seq) => seq.category === category._id
          ) || null;
        if (isExist) {
          indexArray.push({ ...isExist, index });
          return {
            ...category,
            sequence: isExist.sequence,
          };
        }
        return category;
      });

      const sortedTargetKeys = indexArray
        .sort(function (a, b) {
          return a.sequence - b.sequence;
        })
        .map((a) => a.index.toString());

      setCategories(finalCategories);
      setTargetKeys(sortedTargetKeys);
      return;
    }
  };

  const fetchAllCategoriesTypes = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.CATEGORY_GET_ALL_CATEGORIES_TYPES}?limit=1000`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        setCategoriesTypes(response?.data.data.findCategoryTypes);
      }
    } catch (error) {
      NotificationService.error(
        "Error",
        "Something went wrong while getting category types"
      );
    }
  };

  useEffect(() => {
    getCategories();
    fetchAllCategoriesTypes();
  }, [location, categorySequenceId]);

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const getCategorySequencesFromTargetKeys = () => {
    return targetKeys.map((key) => {
      const currentSequence = categories.find(
        (category) => category.key === key
      );
      return currentSequence;
    });
  };

  const onFinishHandler = async (formValues) => {
    const isAllLocationsSelected = form
      .getFieldValue("location")
      .includes("ALL");

    const currentLocations = isAllLocationsSelected
      ? ["ALL"]
      : formValues.location;

    const sequencesFromTargetKeys = getCategorySequencesFromTargetKeys();

    const mappedCategorySequence = sequencesFromTargetKeys.map(
      (category, index) => {
        return {
          category: category._id,
          sequence: index + 1,
        };
      }
    );

    if (mappedCategorySequence.length <= 0) {
      return NotificationService.error(
        "Error",
        "Please Add Category Sequences"
      );
    }

    const createCategorySequencePayload = {
      ...formValues,
      location: currentLocations,
      categorySequences: mappedCategorySequence,
    };

    if (categorySequenceId) {
      try {
        const response = await httpClient.put(
          Urls.GET_CATEGORY_SEQUENCE.replace(
            "#{sequenceId}",
            categorySequenceId
          ),
          createCategorySequencePayload
        );

        if (response.status === HttpStatusCode.Ok) {
          NotificationService.success(
            "Success",
            "Category Sequence Updated Successfully"
          );
        }
        if (response.status === HTTP_OK) {
          navigate("/category/sequence");
        }
      } catch (error) {
        NotificationService.error("Error", error.response.data.payload.message);
      }
      return;
    }
    try {
      const response = await httpClient.post(
        `${Urls.CREATE_CATEGORY_SEQUENCE}`,
        createCategorySequencePayload
      );

      if (response.status === HTTP_OK) {
        NotificationService.success(
          "Success",
          "Category Sequence Added Successfully"
        );
        navigate("/category/sequence");
      }
    } catch (error) {
      NotificationService.error("Error", error.response.data.payload.message);
    }
  };

  const campaignCodeValidator = (_, value) => {
    const regex = /^[a-zA-Z0-9_-]+$/;
    if (!regex.test(value)) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const onSelect = (_value, option) => {
    const allLocations = form.getFieldValue("location");

    if (option.value === "ALL" || allLocations.length === locationArrayLength) {
      const allLocations = LocationJson.map((loc) => loc.code);
      form.setFieldsValue({ location: ["ALL", ...allLocations] });
    }
  };

  const onDeselect = (_value, option) => {
    const allLocations = form.getFieldValue("location");
    const isAllLocationsSelected = allLocations.includes("ALL");

    if (isAllLocationsSelected) {
      const filteredLocations = allLocations.filter((loc) => loc !== "ALL");
      form.setFieldsValue({ location: filteredLocations });
    }
    if (option.value === "ALL") {
      form.setFieldsValue({ location: [] });
    }
  };

  const handlePaginationChange = (page, size) => {
    setPaging({ size, page });
  };

  return (
    <div className="add-edit-category-sequence-container">
      <div className="bread-Crumb-Container">
        <div className="breadcrumb-wrapper">
          <BreadCrumb
            breadCrumbItems={breadCrumbsPathForPages[isEdit ? "Edit" : "Add"]}
          />
        </div>
      </div>
      <Form
        form={form}
        onFinish={onFinishHandler}
        name="category-sequence-form"
        className="category-sequence-form common-form"
      >
        <div className="form-inputs-wrapper">
          <Form.Item
            name="campaignCode"
            label="Campaign Code"
            validateFirst
            rules={[
              {
                required: true,
                message: "Please enter campaign code",
              },
              {
                validator: campaignCodeValidator,
                message: "Please enter valid campaign code",
              },
            ]}
          >
            <Input
              disabled={
                currentSequenceDetails.campaignCode === DEFAULT_SEQUENCE_NAME
              }
              maxLength={255}
              placeholder="Please enter campaign code"
              className="add_form"
            />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: "Please enter the locations",
              },
            ]}
          >
            <Select
              className="ios-type-color"
              allowClear
              mode="multiple"
              loading={
                categorySequenceId && !currentSequenceDetails.location?.length
                  ? true
                  : false
              }
              disabled={
                currentSequenceDetails.campaignCode === DEFAULT_SEQUENCE_NAME
              }
              placeholder="Please select location"
              maxTagCount={"responsive"}
              onSelect={onSelect}
              onDeselect={onDeselect}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  overlayStyle={{
                    pointerEvents: "none",
                  }}
                  title={omittedValues.map(({ label }) => label).join(", ")}
                >
                  <span>+ {omittedValues.length}</span>
                </Tooltip>
              )}
            >
              <Option value={"ALL"}>{`ALL - All locations`}</Option>
              {LocationArray}
            </Select>
          </Form.Item>
          <Form.Item
            name="isEnabled"
            label="Is Campaign Enabled ? "
            initialValue={true}
          >
            <Switch
              checkedChildren="enable"
              unCheckedChildren="disable"
              disabled={
                currentSequenceDetails.campaignCode === DEFAULT_SEQUENCE_NAME
              }
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
            />
          </Form.Item>
        </div>
        <div className="table-transfer-wrapper">
          <TableTransferComponent
            dataSource={categories}
            setCategories={setCategories}
            targetKeys={targetKeys}
            showSearch
            showSelectAll={false}
            onChange={onChange}
            filterOption={filterOption}
            leftColumns={leftColumns}
            rightColumns={columns}
            handleSearch={handleSearch}
            pagination={{
              onChange: (page, pageSize) =>
                handlePaginationChange(page, pageSize),
            }}
          />
        </div>
        <Button type="primary" htmlType="submit">
          {isEdit ? "Edit Category Sequence" : "Add Category Sequence"}
        </Button>
      </Form>
    </div>
  );
};

export default AddOrEditCategorySequence;
