import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { TEMPLATE_ISPREMIUM } from "../../Constant/DropDown";
import { useEffect, useState } from "react";

const AddSticker = (props) => {
  const { onFinish, onFinishFailed, backToListPage, editTemplateData } = props;
  const [addTemplateForm] = Form.useForm();
  const [defaultData, SetDefaultData] = useState(editTemplateData);

  const ImagePreview = ({ imageUrl }) => {
    return imageUrl ? (
      <Image className="img_border" width={140} height={120} src={imageUrl} />
    ) : null;
  };

  const onImageUrlChange = (e) => {
    SetDefaultData({
      ...defaultData,
      thumbnailImage: e.target.value,
    });
  };

  useEffect(() => {
    addTemplateForm.setFieldsValue({
      sequence:
        editTemplateData && editTemplateData.sequence === null
          ? null
          : editTemplateData
          ? editTemplateData.sequence
          : 1,
    });
    if (editTemplateData) {
      addTemplateForm.setFieldsValue({
        valuePropName: editTemplateData
          ? editTemplateData.isPremium
            ? true
            : false
          : false,
      });
    } else {
      addTemplateForm.setFieldsValue({
        valuePropName: false,
      });
    }
  }, [editTemplateData]);

  return (
    <div className="add_stiker_box">
      <div>
        {editTemplateData ? <h4>Edit Sticker</h4> : <h4>Add Sticker</h4>}
      </div>

      <Divider></Divider>
      <Form
        form={addTemplateForm}
        className="common-form"
        name="add-product"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={editTemplateData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item hidden={true} name="_id">
          <Input />
        </Form.Item>
        <div className="add_sti_img">
          <div className="add_sti_width">
            <Form.Item
              label="Premium"
              name="isPremium"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                  message: "Please select template sub type!",
                },
              ]}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>

            <Form.Item
              label="Active Status"
              name="activeStatus"
              valuePropName="checked"
              rules={[
                {
                  required: false,
                  message: "Please select Active Status",
                },
              ]}
            >
              {editTemplateData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked="Yes"
                />
              )}
              {!editTemplateData && (
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked="Yes"
                />
              )}
            </Form.Item>

            <Form.Item
              label="Sequence"
              name="sequence"
              pl
              rules={[
                {
                  required: false,
                  message: "Please input sticker sequence!",
                },
              ]}
            >
              {editTemplateData && (
                <InputNumber
                  placeholder="Please enter sticker sequence"
                  min={editTemplateData.sequence === null ? null : 1}
                  defaultValue={editTemplateData.sequence === null ? null : 1}
                  initialValue={editTemplateData.sequence === null ? null : 1}
                />
              )}

              {!editTemplateData && (
                <InputNumber
                  placeholder="Please enter template sequence"
                  min={1}
                  defaultValue={1}
                  initialValue={1}
                />
              )}
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input sticker name!",
                },
              ]}
            >
              <Input placeholder="Please enter sticker name" />
            </Form.Item>
            <Form.Item
              label="Image URL"
              name="thumbnailImage"
              rules={[
                {
                  required: true,
                  message: "Please input sticker thumbnailImage!",
                },
                {
                  type: "url",
                  warningOnly: true,
                  message: "Please enter valid url",
                },
              ]}
            >
              <Input
                placeholder="Please enter sticker image url"
                onChange={(e) => {
                  onImageUrlChange(e);
                }}
              />
            </Form.Item>
          </div>
          <div className="add_sti_img_align">
            <Form.Item label="">
              <ImagePreview imageUrl={defaultData?.thumbnailImage} />
            </Form.Item>
          </div>
        </div>

        <Divider></Divider>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space>
            <Button
              type="default"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                backToListPage();
              }}
            >
              Back
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddSticker;
