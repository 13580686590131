import {
  DeleteOutlined,
  PlusSquareOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
  Modal,
  Input,
} from "antd";
import moment from "moment";
import BreadCrumb from "../Common/breadCrumb";
import { breadCrumbsPathForPages, isObjEmpty } from "../../Util/commonUtility";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import TotalReocordsInListingComponent from "../Common/totalRecordListing";
const { confirm } = Modal;

const CategoryTypeListing = (props) => {
  const {
    dataSource,
    addCategory,
    deleteCategory,
    editCategory,
    paging,
    handlePageChange,
    categoriesTypeOptions,
    userAdmines,
    totalRecords,
    template,
    filterData,
    sortOrder,
    sortField,
    onFilterFinish,
    onFilterClear,
    sortTitle,
    handleSort,
    handleFilter,
    handleSearch,
    handleReset,
    searchInput,
    searchedText,
  } = props;
  const navigate = useNavigate();

  const catClicked = (data) => {
    navigate("template", {
      state: {
        categoryData: data,
        catTypeData: categoriesTypeOptions,
        userAdmines: userAdmines,
        template: template,
      },
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure delete this category?",
      icon: <ExclamationCircleFilled />,
      content: "Also delete all the data of this category",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteCategory(record);
      },
      onCancel() {},
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          defaultValue={searchedText}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys[0])}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    render: (text) => text,
  });

  const columns = [
    {
      title: "Category Type",
      dataIndex: "name",
      key: "name",

      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
      ...getColumnSearchProps("name"),
      render: (_, record) => (
        <>
          <span className="category_name">{record.name}</span>
        </>
      ),
    },
    {
      title: "Active Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
      render: (text) => (
        <Tag
          className="common-tag-color"
          color={text === true ? "green" : "red"}
        >
          {String(text ? "Yes" : "No")}
        </Tag>
      ),
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
      sorter: true,
      sortOrder: sortOrder,
      onHeaderCell: (column) => ({
        onClick: () => handleSort(column.dataIndex, sortOrder),
      }),
    },

    // {
    //   title: "OsType",
    //   dataIndex: "osType",
    //   key: "osType",
    //   render: (osType) => {
    //     return (
    //       <>
    //         {osType?.map((osType, index) => {
    //           return (
    //             <Tag key={index} className="common-tag-color" color="blue">
    //               {" "}
    //               {osType}{" "}
    //             </Tag>
    //           );
    //         })}
    //       </>
    //     );
    //   },
    // },

    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (text, record) => {
        return (
          <Space>
            {/* <i
              class="bi bi-eye"
              title="Details"
              onClick={() => {
                catClicked(record);
              }}
            ></i> */}

            <i
              class="bi bi-pencil-fill"
              title="Edit"
              onClick={() => {
                editCategory(record);
              }}
            ></i>

            <Popconfirm
              className="delete_pop_box"
              title="Are you sure to delete this category ?"
              disabled={record.name === "template" || record.name === "sticker"}
              onConfirm={() => {
                // deleteCategory(record);
                {
                  showDeleteConfirm(record);
                }
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>

            <Tooltip
              color="geekblue"
              title={
                <>
                  Updated By :{"  "} {record.updatedBy} <br />
                  Created By :{"  "} {record.createdBy} <br />
                  Created At :{"  "}
                  {moment(record.createdAt).format("DD/MMM/YY hh:mm A")}
                  <br />
                  Updated At :{"  "}
                  {moment(record.updatedAt).format("DD/MMM/YY hh:mm A")}{" "}
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="bread-Crumb-Container">
        <div className="fs-name">
          <BreadCrumb breadCrumbItems={breadCrumbsPathForPages.categoryType} />
        </div>
        <Button
          className="add_btn"
          type="primary"
          onClick={() => {
            addCategory();
          }}
        >
          <PlusSquareOutlined />
          Add Category Type
        </Button>
      </div>

      <Table
        className="listCategory"
        dataSource={dataSource}
        pagination={{
          pageSize: paging.pageSize,
          showSizeChanger: true,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent totalRecords={totalRecords} />
          ),
        }}
        columns={columns}
        rowKey={"_id"}
        size="small"
      />
    </>
  );
};
export default CategoryTypeListing;
