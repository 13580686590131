export const PRODUCT_TYPE = [
  {
    label: "Desktop",
    value: "Desktop",
  },
  {
    label: "Mobile",
    value: "Mobile",
  },
  {
    label: "Web", 
    value: "Web",
  }, 
];

export const OS_TYPE = [
  {
    label: "ios",
    value: "ios",
  },
  {
    label: "Android",
    value: "android",
  },
];


export const HTML_TYPE = [
  {
    label: "text",
    value: "text",
  },
  {
    label: "boolean",
    value: "boolean",
  },
];

export const HTML_TYPE_DEFAULT_VALUE = [
  {
    label: "true",
    value: "true",
  },
  {
    label: "false",
    value: "false",
  },
];


export const ORDER = [
  {
    label: "Asc",
    value: "asc",
  },
  {
    label: "Desc",
    value: "desc",
  },
];


export const SORTFIELD = [
  {
    label: "sequence",
    value: "sequence",
  },
  {
    label: "createdAt",
    value: "createdAt",
  },
  {
    label: "name",
    value: "name",
  },
];



export const CATEGORY_TYPE = [
  {
    label: "Template",
    value: "template",
  },
  {
    label: "Sticker",
    value: "sticker",
  },
];

export const TEMPLATE_TYPE = [
  {
    label: "template",
    value: "template",
  },
  {
    label: "sticker",
    value: "sticker",
  },
];

export const TEMPLATE_ISPREMIUM = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];

export const LAYER_COUNT = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
];

export const LAYER_TYPE = [
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Sticker",
    value: "sticker",
  },
];
export const TARGET_ATTRIBUTE_TYPE = [
  {
    label: "String",
    value: "String",
  },
  {
    label: "Date",
    value: "Date",
  },
  {
    label: "Boolean",
    value: "Boolean",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Version",
    value: "Version",
  },
];



export const ROLE = [
  {
    label: "admin",
    value: "Admin",
  },
 
];