import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Select, Space } from "antd";
import { useNavigate } from "react-router-dom";

const EnvironmentComponent = () => {
  const navigate = useNavigate();

  const handleChange = (data) => {};

  const onFinish = async (data) => {
    localStorage.setItem("SelectedEnv", data.envSelect.value);

    navigate("/login");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Row>
        <Col>
          <Card className="env_box">
            <Space direction="vertical">
              <h1>Please Select Environment</h1>
              <Form onFinish={onFinish} name="environment">
                <div className="env_btn_box">
                  <Form.Item
                    name="envSelect"
                    rules={[
                      {
                        required: true,
                        message: "Please select an Environment",
                      },
                    ]}
                  >
                    <Select
                      className="env_btn_size"
                      placeholder="Please select an Environment"
                      labelInValue
                      onChange={(e) => handleChange(e)}
                      options={[
                        {
                          value: "production",
                          label: "Production",
                        },
                        {
                          value: "devlopment",
                          label: "Devlopment",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Go To <ArrowRightOutlined />
                      </Button>
                    </Space>
                  </Form.Item>
                </div>
              </Form>
            </Space>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EnvironmentComponent;
