import {  configureStore } from "@reduxjs/toolkit";
import persistedReducer from "./Reducers";
// import persistState from "redux-localstorage";
// compose,
import { persistStore } from 'redux-persist';
// const enhancer = compose(persistState("localdata", "deco-app-local-data"));
const store = configureStore({
  reducer: persistedReducer,
 
});
export const persistor = persistStore(store);
export default store;
